import { useReducer } from 'react';
import authReduce, { authDefault } from '../reducers/auth';
import corretoraReduce, { corretoraDefault } from '../reducers/corretora';
import unidadesReduce, { unidadesDefault } from '../reducers/unidades';
import contratosReduce, { contratosDefault } from '../reducers/contratos';
import tabelasReduce, { tabelasDefault } from '../reducers/tabelas';
import seguradosReduce, { seguradosDefault } from '../reducers/segurados';
import faturasReduce, { faturasDefault } from '../reducers/faturas';
import processosReduce, { processosDefault } from '../reducers/processos';
import dashboardReduce, { dashboardDefault } from '../reducers/dashboard';
import atendimentosReduce, { atendimentosDefault } from '../reducers/atendimentos';
import movimentosReduce, { movimentosDefault } from '../reducers/movimentos';
import themeReduce, { themeDefault } from '../reducers/theme';
import arquivosReduce, { arquivosDefault } from '../reducers/arquivos';
import regrasVidaReduce, { regrasVidaDefault } from '../reducers/regrasVida';

const useCombinedReducers = () => {

    const [authStore, setAuthStore] = useReducer(authReduce, authDefault);
    const [corretoraStore, setCorretoraStore] = useReducer(corretoraReduce, corretoraDefault);
    const [unidadesStore, setUnidadesStore] = useReducer(unidadesReduce, unidadesDefault);
    const [contratosStore, setContratosStore] = useReducer(contratosReduce, contratosDefault);
    const [tabelasStore, setTabelasStore] = useReducer(tabelasReduce, tabelasDefault);
    const [seguradosStore, setSeguradosStore] = useReducer(seguradosReduce, seguradosDefault);
    const [faturasStore, setFaturasStore] = useReducer(faturasReduce, faturasDefault);
    const [processosStore, setProcessosStore] = useReducer(processosReduce, processosDefault);
    const [dashboardStore, setDashboardStore] = useReducer(dashboardReduce, dashboardDefault);
    const [atendimentosStore, setAtendimentosStore] = useReducer(atendimentosReduce, atendimentosDefault);
    const [movimentosStore, setMovimentosStore] = useReducer(movimentosReduce, movimentosDefault);
    const [themeStore, setThemeStore] = useReducer(themeReduce, themeDefault);
    const [arquivosStore, setArquivosStore] = useReducer(arquivosReduce, arquivosDefault);
    const [regrasVidaStore, setRegrasVidaStore] = useReducer(regrasVidaReduce, regrasVidaDefault);

    return {
        store: {
            ...authStore,
            ...corretoraStore,
            ...unidadesStore,
            ...contratosStore,
            ...tabelasStore,
            ...seguradosStore,
            ...faturasStore,
            ...processosStore,
            ...dashboardStore,
            ...atendimentosStore,
            ...movimentosStore,
            ...themeStore,
            ...arquivosStore,
            ...regrasVidaStore
        },
        reducers: [
            setAuthStore,
            setCorretoraStore,
            setUnidadesStore,
            setContratosStore,
            setTabelasStore,
            setSeguradosStore,
            setFaturasStore,
            setProcessosStore,
            setDashboardStore,
            setAtendimentosStore,
            setMovimentosStore,
            setThemeStore,
            setArquivosStore,
            setRegrasVidaStore
        ]
    };

}

export default useCombinedReducers;