const GET_CONTRATOS = 'GET_CONTRATOS';
const LOADING_CONTRATOS = 'LOADING_CONTRATOS';
const SELECIONAR_CONTRATO = 'SELECIONAR_CONTRATO';
const CONTRATO_NOT_FOUND = 'CONTRATO_NOT_FOUND';

const GET_DADOS_CONTRATOS = 'GET_DADOS_CONTRATOS';
const DADOS_CONTRATO_NOT_FOUND = 'DADOS_CONTRATO_NOT_FOUND';
const LOADING_DADOS_CONTRATOS = 'LOADING_DADOS_CONTRATOS';

const GET_CONTRATOS_COBERTURAS = 'GET_CONTRATOS_COBERTURAS';
const CONTRATOS_COBERTURAS_NOT_FOUND = 'CONTRATOS_COBERTURAS_NOT_FOUND';
const LOADING_CONTRATO_COBERTURAS = 'LOADING_CONTRATO_COBERTURAS';

const GET_ARQUIVOS_SINISTRALIDADE = 'GET_ARQUIVOS_SINISTRALIDADE';
const ARQUIVOS_SINISTRALIDADE_NOT_FOUND = 'ARQUIVOS_SINISTRALIDADE_NOT_FOUND';
const LOADING_ARQUIVOS_SINISTRALIDADE = 'LOADING_ARQUIVOS_SINISTRALIDADE';

const GET_ARQUIVOS_CONTRATOS = 'GET_ARQUIVOS_CONTRATOS';
const ARQUIVOS_CONTRATOS_NOT_FOUND = 'ARQUIVOS_CONTRATOS_NOT_FOUND';
const LOADING_ARQUIVOS_CONTRATOS = 'LOADING_ARQUIVOS_CONTRATOS';

export {
    GET_CONTRATOS,
    LOADING_CONTRATOS,
    CONTRATO_NOT_FOUND,
    SELECIONAR_CONTRATO,

    GET_DADOS_CONTRATOS,
    DADOS_CONTRATO_NOT_FOUND,
    LOADING_DADOS_CONTRATOS,

    GET_CONTRATOS_COBERTURAS,
    CONTRATOS_COBERTURAS_NOT_FOUND,
    LOADING_CONTRATO_COBERTURAS,

    GET_ARQUIVOS_SINISTRALIDADE,
    ARQUIVOS_SINISTRALIDADE_NOT_FOUND,
    LOADING_ARQUIVOS_SINISTRALIDADE,

    GET_ARQUIVOS_CONTRATOS,
    ARQUIVOS_CONTRATOS_NOT_FOUND,
    LOADING_ARQUIVOS_CONTRATOS
};