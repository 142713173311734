import { useCallback, useEffect, useState } from 'react';
import { useLocation, Link as RouterLink, Redirect } from 'react-router-dom';

import ConnectTo from '../../store/connect';

import { setTheme } from '../../store/reducers/theme/action';
import { logout, habilitarTrocaSenha } from '../../store/reducers/auth/actions';
import { getDadosCorretora } from '../../store/reducers/corretora/actions';

import MenuItems from './MenuItem';
import LogoCorretora from '../../components/LogoCorretora';

const menuItems = [
    {
        level: 1,
        description: 'Dashboard',
        href: '/',
        idMapa: "0"
    },
    {
        level: 1,
        description: 'Contratos',
        href: '/contratos',
        idMapa: "1"
    },
    {
        level: 1,
        description: 'Tabela de Valores',
        href: '/tabelas_valores',
        idMapa: "4"
    },
    {
        level: 1,
        description: 'Segurados',
        href: '/segurados',
        idMapa: "9"
    },
    {
        level: 1,
        description: 'Faturas',
        href: '/faturas',
        idMapa: "14"
    },
    {
        level: 1,
        description: 'Atendimentos',
        href: '/atendimentos',
        idMapa: "27"
    },
    {
        level: 1,
        description: 'Movimentação',
        href: '/movimentacao',
        idMapa: "32"
    },
    {
        level: 1,
        description: 'Arquivos',
        href: '/arquivos',
        idMapa: "23"
    }
]

const Menu = props => {

    const { auth, dispatch, corretora } = props;
    const themeRef = localStorage.getItem('theme');
    const isLight = themeRef === 'light';
    const [modoTheme, setModoTheme] = useState(isLight);
    const [isMounted, setIsMounted] = useState(false);
    const [trocarSenha, setTrocaSenha] = useState(false);
    const location = useLocation();

    const load = useCallback(async codigoUsuario => {
        codigoUsuario && dispatch(await getDadosCorretora(codigoUsuario))
    }, [dispatch])

    useEffect(() => {
        const navbarStyle = localStorage.getItem("navbarStyle");
        navbarStyle && navbarStyle !== 'transparent' && document.querySelector('.navbar-vertical').classList.add(`navbar-${navbarStyle}`);

        const navbarVertical = document.querySelector('.navbar-vertical');
        const navbarTopVertical = document.querySelector('.content .navbar-top');
        const navbarTop = document.querySelector('[data-layout] .navbar-top');
        const navbarTopCombo = document.querySelector('.content [data-navbar-top="combo"]');
        navbarTop && navbarTop.removeAttribute('style');
        navbarTopVertical && navbarTopVertical.remove(navbarTopVertical);
        navbarVertical && navbarVertical.remove(navbarVertical);
        navbarTopCombo && navbarTopCombo.remove(navbarTopCombo);

        if (!isMounted) {
            load(auth?.codigoUsuario);
            setIsMounted(true);
        }
    }, [isMounted, load, auth]);

    const onAlterTheme = value => {

        const valueTheme = value ? 'light' : 'dark';
        localStorage.setItem('theme', valueTheme);
        setModoTheme(value);

        if (localStorage.getItem('theme') === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }

        dispatch(setTheme(valueTheme));

    }

    const handleSair = () => {
        dispatch(logout());
    }

    const handleTrocarSenha = () => {
        setTrocaSenha(true);
        dispatch(habilitarTrocaSenha(auth));
    }

    const filterMapa = m => auth && auth.mapaAcesso ? auth.mapaAcesso.includes(m.idMapa) : false;

    if (trocarSenha) {
        return <Redirect to="/login" />;
    }

    return (
        <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand-xl" style={{ display: 'none' }}>
            <button className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>
            <RouterLink className="navbar-brand me-1 me-sm-3" to="/">
                <div className="d-flex align-items-center" style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <LogoCorretora size={25} style={{ marginRight: 5, marginLeft: 10 }} />
                    <span className="font-sans-serif ps-2">Portal do RH</span>
                </div>
            </RouterLink>
            <div className="collapse navbar-collapse scrollbar" id="navbarStandard">
                <ul id="ulMenu" className="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
                    {
                        menuItems
                            .filter(filterMapa)
                            .map((item, i) => {
                                const classSel = item.href === location.pathname ? 'nav-link menuActive' : 'nav-link';
                                return <MenuItems key={i} item={item} classSel={classSel} />
                            })
                    }
                </ul>
            </div>
            <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
                <li className="nav-item dropdown"><a className="nav-link pe-0" id="navbarDropdownUser" href="index.html" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="d-flex align-items-center">
                        <span className="font-sans-serif">{corretora?.nome_usuario || 'Carregando...'}</span>
                        <div className="avatar avatar-xl" style={{ marginLeft: 10 }}>
                            <img className="rounded-circle" src="assets/img/team/avatar.png" alt="" />
                        </div>
                    </div>
                </a>
                    <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
                        <div className="bg-white dark__bg-1000 rounded-2 py-2">
                            <div className="dropdown-item">
                                <div className="form-check form-switch mb-0" style={{ minHeight: 0 }}>
                                    <input className="form-check-input" id="modoTheme" type="checkbox" checked={modoTheme} onChange={e => onAlterTheme(e.target.checked)} style={{ marginTop: 5, cursor: 'pointer' }} />
                                    <label
                                        className="form-check-label mb-0"
                                        htmlFor="modoTheme"
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Modo {modoTheme ? 'Claro' : 'Escuro'}
                                    </label>
                                </div>
                            </div>
                            <div className="dropdown-divider"></div>
                            <span
                                className="dropdown-item"
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleTrocarSenha()}
                            >
                                Trocar Senha
                            </span>
                            <div className="dropdown-divider"></div>
                            <span
                                className="dropdown-item"
                                onClick={() => handleSair()}
                                style={{ cursor: 'pointer' }}
                            >
                                Sair
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    )
}

const mapStateToProps = ({ auth, corretora, theme }, props) => {
    return {
        auth,
        corretora,
        theme,
        ...props
    }
}

export default ConnectTo(mapStateToProps)(Menu);