import { SET_THEME } from './type';

export const themeDefault = {
    theme: null
}

const themeReducer = (state = themeDefault, action) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.payload
            }
        default: return state;
    }

}

export default themeReducer;