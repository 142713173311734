const GET_ATENDIMENTOS = 'GET_ATENDIMENTOS';
const LOADING_ATENDIMENTOS = 'LOADING_ATENDIMENTOS';
const ATENDIMENTOS_NOT_FOUND = 'ATENDIMENTOS_NOT_FOUND';

const FILTRO_ATENDIMENTOS = 'FILTRO_ATENDIMENTOS';
const LOADING_FILTROS_ATENDIMENTOS = 'LOADING_FILTROS_ATENDIMENTOS';
const FILTRO_ATENDIMENTOS_NOT_FOUND = 'FILTRO_ATENDIMENTOS_NOT_FOUND';

const GET_ATENDIMENTO_RASTREIO = 'GET_ATENDIMENTOS_RASTREIO';
const LOADING_ATENDIMENTO_RASTREIO = 'LOADING_ATENDIMENTO_RASTREIO';
const ATENDIMENTO_RASTREIO_NOT_FOUND = 'ATENDIMENTOS_NOT_FOUND';

const GET_ATENDIMENTO_ASSUNTOS = 'GET_ATENDIMENTO_ASSUNTOS';
const LOADING_ATENDIMENTO_ASSUNTOS = 'LOADING_ATENDIMENTO_ASSUNTOS';
const ATENDIMENTO_ASSUNTOS_NOT_FOUND = 'ATENDIMENTO_ASSUNTOS_NOT_FOUND';

const GET_LISTA_DOCUMENTOS = 'GET_LISTA_DOCUMENTOS';
const LOADING_LISTA_DOCUMENTOS = 'LOADING_LISTA_DOCUMENTOS';
const LISTA_DOCUMENTOS_NOT_FOUND = 'LISTA_DOCUMENTOS_NOT_FOUND';

export {
    GET_ATENDIMENTOS,
    LOADING_ATENDIMENTOS,
    ATENDIMENTOS_NOT_FOUND,

    FILTRO_ATENDIMENTOS,
    LOADING_FILTROS_ATENDIMENTOS,
    FILTRO_ATENDIMENTOS_NOT_FOUND,

    GET_ATENDIMENTO_RASTREIO,
    LOADING_ATENDIMENTO_RASTREIO,
    ATENDIMENTO_RASTREIO_NOT_FOUND,

    GET_ATENDIMENTO_ASSUNTOS,
    LOADING_ATENDIMENTO_ASSUNTOS,
    ATENDIMENTO_ASSUNTOS_NOT_FOUND,

    GET_LISTA_DOCUMENTOS,
    LOADING_LISTA_DOCUMENTOS,
    LISTA_DOCUMENTOS_NOT_FOUND
}