import { GET_CORRETORAS } from './types';
import { LOGOUT } from '../auth/types';

export const corretoraDefault = {
    corretora: null
}

const corretoraReducer = (state = corretoraDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return corretoraDefault
        case GET_CORRETORAS:
            return {
                ...state,
                corretora: action.payload
            }
        default: return state
    }
}

export default corretoraReducer;