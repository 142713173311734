import {
    LOGIN,
    LOGOUT,
    GUARD,
    LOADING,
    USER_NOTFOUND
} from './types';

export const authDefault = {
    auth: null,
    isAuthenticated: false,
    isLoading: false,
    userNotFound: false
}

const authReduce = (state = authDefault, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                auth: action.payload,
                isAuthenticated: true,
                isLoading: false,
            }
        case GUARD:
            return {
                ...state,
                auth: action.payload,
                isAuthenticated: true,
                isLoading: false,
            }
        case LOADING:
            return {
                ...state,
                isLoading: true,
                userNotFound: false
            }
        case USER_NOTFOUND:
            return {
                ...authDefault,
                userNotFound: true
            }        
        case LOGOUT:
            return authDefault
        default: return state;
    }
}

export default authReduce;