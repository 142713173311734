const GET_VIGENCIAS = 'GET_VIGENCIAS';
const SET_VIGENCIA = 'SET_VIGENCIA';
const LOADING_VIGENCIA = 'LOADING_VIGENCIA';
const VIGENCIAS_NOT_FOUND = 'VIGENCIAS_NOT_FOUND';

const GET_TABELAS = 'GET_TABELAS'
const TABELAS_NOT_FOUND = 'TABELAS_NOT_FOUND';
const LOADING_TABELA = 'LOADING_TABELA';

const GET_ALTERACOES = 'GET_ALTERACOES'
const ALTERACOES_NOT_FOUND = 'ALTERACOES_NOT_FOUND';
const LOADING_ALTERACOES = 'LOADING_ALTERACOES';

const GET_APORTES = 'GET_APORTES'
const APORTES_NOT_FOUND = 'APORTES_NOT_FOUND';
const LOADING_APORTES = 'LOADING_APORTES';

const GET_EXEMPLOS_REEMBOLSO = 'GET_EXEMPLOS_REEMBOLSO'
const EXEMPLOS_REEMBOLSO_NOT_FOUND = 'EXEMPLOS_REEMBOLSO_NOT_FOUND';
const LOADING_EXEMPLOS_REEMBOLSO = 'LOADING_EXEMPLOS_REEMBOLSO';

export { 
    
    GET_VIGENCIAS, 
    SET_VIGENCIA, 
    LOADING_VIGENCIA, 
    VIGENCIAS_NOT_FOUND, 
    
    GET_TABELAS, 
    TABELAS_NOT_FOUND, 
    LOADING_TABELA, 

    GET_ALTERACOES,
    ALTERACOES_NOT_FOUND,
    LOADING_ALTERACOES,

    GET_APORTES,
    APORTES_NOT_FOUND,
    LOADING_APORTES,

    GET_EXEMPLOS_REEMBOLSO,
    EXEMPLOS_REEMBOLSO_NOT_FOUND,
    LOADING_EXEMPLOS_REEMBOLSO
    
};