import { typeMenuItem } from '../../utils/typeMenuItem';
import { Link as RouterLink } from 'react-router-dom';

const MenuItem = props => {

    const { item, classSel } = props;
    const isContentSubItem = item.subItems && item.subItems.length > 0;

    return (
        <li className="nav-item dropdown">
            {
                isContentSubItem ?
                    <>
                        <RouterLink
                            className={`nav-link dropdown-toggle`}
                            to={item.href}
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            id="dashboard"
                        >
                            {item.description}
                        </RouterLink>
                        <div className="dropdown-menu dropdown-menu-card border-0 mt-0" aria-labelledby="dashboard">
                            <div className="bg-white dark__bg-1000 rounded-3 py-2">
                                {item.subItems.map((subItem, i) => {

                                    if (subItem.type === typeMenuItem.title) return <p key={i} className="nav-link text-700 mb-0 fw-bold">{subItem.description}</p>
                                    if (subItem.type === typeMenuItem.itemMenu) return <RouterLink to={item.href} key={i} className="dropdown-item link-600 fw-medium">{subItem.description}</RouterLink>
                                    return '';

                                })}
                            </div>
                        </div>
                    </>
                    :
                    <RouterLink
                        to={item.href}
                        className={classSel}
                    >{item.description}</RouterLink>
            }
        </li>
    )
}

export default MenuItem