import { LOGIN, LOGOUT, GUARD, LOADING, USER_NOTFOUND } from './types';
import axios from '../../../lib/axios';

const clearConex = () => {
    delete axios.defaults.headers.common['codigo_usuario']
    delete axios.defaults.headers.common['nome_tela'];
    delete axios.defaults.headers.common['data_conn'];
    localStorage.removeItem('ref');
}

const montarPayloadUser = data => {
    return({
        codigoCliente: data[2],
        codigoUsuario: data[3],
        codigoUsuarioGestor: data[4],
        codigoFilial: data[1],
        mapaAcesso: !!data[5] ? ["0", ...data[5].split(',')] : ["0"],
        troca: parseInt(data[6]),
        senhaInvalida: false
    })
}

export const login = values => {
    return new Promise(async (resolve) => {
        try {
            const auth = await axios.post('area_cliente/validacao', {
                Email: values.email,
                Senha: values.senha
            });
            if (auth.data) {
                try {

                    const user = await axios.post('clientes/contatos/area_cliente/validacao', { r: auth.data });
                    const data = user.data.split('|');

                    axios.defaults.headers.common['codigo_usuario'] = data[3];
                    axios.defaults.headers.common['nome_tela'] = 'Portal do Cliente';
                    axios.defaults.headers.common['data_conn'] = data[0];
                    axios.defaults.headers.common['codigo_filial'] = data[1];
 
                    localStorage.setItem('ref', auth.data);

                    resolve({
                        type: LOGIN,
                        payload: montarPayloadUser(data)
                    });
                }
                catch(e) {                    
                    clearConex();
                    resolve({ type: USER_NOTFOUND });
                }
            } else {
                resolve({ type: USER_NOTFOUND });
            }
        }

        catch {
            resolve({ type: LOGOUT });
        }
    });
}

export const guard = () => {
    return new Promise(async (resolve) => {
        try {

            const ref = localStorage.getItem('ref');
            const user = await axios.post('clientes/contatos/area_cliente/validacao', { r: ref });

            if (!user.data) {
                clearConex();
                resolve({ type: LOGOUT });
                return false;
            }

            const data = user.data.split('|');

            axios.defaults.headers.common['codigo_usuario'] = data[3];
            axios.defaults.headers.common['nome_tela']      = 'Portal do Cliente';
            axios.defaults.headers.common['data_conn']      = data[0];
            axios.defaults.headers.common['codigo_filial']  = data[1];

            resolve({
                type: GUARD,
                payload: montarPayloadUser(data)
            });
        }
        catch {
            clearConex();
            resolve({ type: USER_NOTFOUND });
        }
    });
}

export const habilitarTrocaSenha = auth => {
    return {
        type: LOGIN,
        payload: {
            ...auth,
            troca: 1
        }
    }
}

export const logout = () => {
    clearConex();
    return { type: LOGOUT };
}

export const loading = () => {
    return { type: LOADING };
}

export const trocarSenha = values => {
    return new Promise(async resolve => {
        try {
            await axios.post('area_cliente/troca', {
                codigoContato: values.auth.codigoUsuario,
                novaSenha: values.novaSenha
            });
            resolve({
                type: LOGIN,
                payload: {
                    ...values.auth,
                    senhaInvalida: false,
                    troca: 0
                }
            });
        }

        catch {      
            console.clear();      
            resolve({
                type: LOGIN,
                payload: {
                    ...values.auth,
                    senhaInvalida: true
                }
            });
        }
    });
}