import useCombinedReducers from "./hooks/useCombinedReducers";
import { StoreContext } from './hooks/useStore';

const Provider = ({ children }) => {

    const { store, reducers } = useCombinedReducers();

    const triggerDispatchs = action => {
        reducers.forEach(async item => {
            if (typeof item === 'function') item(action);
        });
    };

    return (
        <StoreContext.Provider
            value={{
                store,
                dispatch: triggerDispatchs
            }}
        >
            {children}
        </StoreContext.Provider>
    );
};

export default Provider;