import { useEffect, useState } from "react";
import { lottieInit } from '../../../lib/function';

const initialForm = {
    novaSenha: '',
    confirmarSenha: ''
}

const FormTroca = props => {

    const { onCall, onLogout, loading, statusSenha } = props;
    const [form, setForm] = useState(initialForm);
    const [senhaInvalida, setSenhaInvalida] = useState(statusSenha);

    const validarSenha = () => {

        let retorno = true;

        if (!form.novaSenha.trim()) {
            setSenhaInvalida(true);
            retorno = false;
        } else if (!form.confirmarSenha.trim()) {
            setSenhaInvalida(true);
            retorno = false;
        } else if (form.novaSenha !== form.confirmarSenha) {
            setSenhaInvalida(true);
            retorno = false;
        } else if (form.novaSenha.length < 6) {
            setSenhaInvalida(true);
            retorno = false;
        } else if (form.novaSenha.indexOf('123456') !== -1) {
            setSenhaInvalida(true);
            retorno = false;
        }

        return retorno;
    }

    const handleSubmit = e => {
        e.preventDefault();
        if (validarSenha()) {
            onCall(form);
        };
    }

    const handleChange = (value, key) => {
        senhaInvalida && setSenhaInvalida(false);
        setForm({
            ...form,
            [key]: value
        })
    }

    const handleKeyDown = e => {
        if(e.key === 'Enter') {
            handleSubmit(e);
        }
    }

    useEffect(() => {
        lottieInit();
    });

    return (
        <div style={{ minHeight: '220px' }}>

            {
                !loading ?
                    <>
                        <div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="card-new-password">Nova Senha</label>
                                <input
                                    className="form-control"
                                    id="card-new-password"
                                    type="password"
                                    autoFocus={true}
                                    value={form.novaSenha}
                                    onChange={e => handleChange(e.target.value, 'novaSenha')}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="d-flex justify-content-between">
                                    <label className="form-label" htmlFor="card-confirm-password">Confirmar Senha</label>
                                </div>
                                <input
                                    className="form-control"
                                    id="card-confirm-password"
                                    type="password"
                                    value={form.confirmarSenha}
                                    onKeyDown={handleKeyDown}
                                    onChange={e => handleChange(e.target.value, 'confirmarSenha')}
                                />
                            </div>
                            {
                                senhaInvalida ?
                                    <div className="alert alert-danger text-center" role="alert">Senha Inválida!</div>
                                    : false
                            }
                            <div className="d-flex mb-3">
                                <div className="col-6 pe-1">
                                    <button
                                        className="btn btn-danger d-block w-100 mt-3"
                                        onClick={onLogout}
                                    >
                                        Sair
                                    </button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className="btn btn-primary d-block w-100 mt-3"
                                        onClick={handleSubmit}
                                    >
                                        Efetuar Troca
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: '220px' }}>
                        <div
                            className="lottie mx-auto"
                            data-options='{"path":"../../../assets/img/animated-icons/loading-login.json"}'
                        ></div>
                    </div>
            }

        </div>
    )
}

export default FormTroca;