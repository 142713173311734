import {

    GET_VIGENCIAS_REGRAS,
    SET_VIGENCIA_REGRA,
    LOADING_VIGENCIA_REGRA,
    VIGENCIAS_REGRAS_NOT_FOUND,

    GET_REGRAS_VIDA,
    REGRAS_VIDA_NOT_FOUND,
    LOADING_REGRA_VIDA

} from './types';
import { LOGOUT } from '../auth/types';

export const regrasVidaDefault = {
    vigenciasRegras: {
        lista: null,
        codigo_vigencia: null,
        tipo_regra: null,
        codigo_contrato: null,
        loading: false
    },
    regrasVida: {
        data: null,
        tipo_regra: null,
        codigo_vigencia: null,
        loading: false
    },
}

const tabelasReducer = (state = regrasVidaDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return regrasVidaDefault

        case GET_VIGENCIAS_REGRAS:
            return {
                ...state,
                vigenciasRegras: {
                    ...state.vigenciasRegras,
                    lista: action.payload.data,
                    codigo_vigencia: action.payload.data.length > 0 ? action.payload.data[0].cod_contrato_vida_regra : null,
                    tipo_regra: action.payload.data.length > 0 ? action.payload.data[0].tipo_regra : null,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case SET_VIGENCIA_REGRA:
            return {
                ...state,
                vigenciasRegras: {
                    ...state.vigenciasRegras,
                    codigo_vigencia: action.payload.codigo,
                    tipo_regra: action.payload.tipo,
                }
            }
        case LOADING_VIGENCIA_REGRA:
            return {
                ...state,
                vigenciasRegras: {
                    ...state.vigenciasRegras,
                    lista: null,
                    loading: true
                }
            }
        case VIGENCIAS_REGRAS_NOT_FOUND:
            return {
                ...state,
                vigenciasRegras: {
                    lista: null,
                    codigo_vigencia: null,
                    tipo_regra: null,
                    loading: false
                }
            }

        case GET_REGRAS_VIDA:
            return {
                ...state,
                regrasVida: {
                    ...state.regrasVida,
                    data: action.payload.data,
                    tipo_regra: action.payload.tipoRegra,
                    codigo_vigencia: action.payload.codigo_vigencia,
                    loading: false
                }
            }
        case LOADING_REGRA_VIDA:
            return {
                ...state,
                regrasVida: {
                    ...state.regrasVida,
                    data: null,
                    loading: true
                }
            }
        case REGRAS_VIDA_NOT_FOUND:
            return {
                ...state,
                regrasVida: {
                    data: null,
                    tipo_regra: null,
                    codigo_vigencia: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default tabelasReducer;