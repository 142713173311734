import {
    GET_CONTRATOS,
    LOADING_CONTRATOS,
    SELECIONAR_CONTRATO,
    CONTRATO_NOT_FOUND,

    GET_DADOS_CONTRATOS,
    DADOS_CONTRATO_NOT_FOUND,
    LOADING_DADOS_CONTRATOS,

    GET_CONTRATOS_COBERTURAS,
    CONTRATOS_COBERTURAS_NOT_FOUND,
    LOADING_CONTRATO_COBERTURAS,

    GET_ARQUIVOS_SINISTRALIDADE,
    ARQUIVOS_SINISTRALIDADE_NOT_FOUND,
    LOADING_ARQUIVOS_SINISTRALIDADE,

    GET_ARQUIVOS_CONTRATOS,
    ARQUIVOS_CONTRATOS_NOT_FOUND,
    LOADING_ARQUIVOS_CONTRATOS
} from './types';
import { LOGOUT } from '../auth/types';

export const contratosDefault = {
    contratos: {
        lista: null,
        codigo_contrato: null,
        codigo_unidade: null,
        hasTodos: false,
        hasSelecione: false,
        loading: false
    },
    contrato_dados: {
        dados: null,
        codigo_contrato: null,
        loading: false
    },
    contrato_coberturas: {
        dados: null,
        codigo_contrato: null,
        loading: false
    },
    arquivosSinistralidade: {
        data: null,
        codigo_contrato: null,
        tipo_contrato: null,
        loading: false
    },
    arquivosContratos: {
        data: null,
        codigo_contrato: null,
        codigo_area: null,
        loading: false
    }
}

const contratosReducer = (state = contratosDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return contratosDefault;

        case GET_CONTRATOS:
            return {
                ...state,
                contratos: {
                    ...state.contratos,
                    lista: action.payload.data,
                    codigo_unidade: action.payload.codigo_unidade,
                    codigo_contrato: null,//action.payload.data && action.payload.data.length > 0 ? parseInt(action.payload.data[0].codigo) : null,
                    hasTodos: action.payload.hasTodos,
                    hasSelecione: action.payload.hasSelecione,
                    loading: false
                }
            }
        case SELECIONAR_CONTRATO:
            return {
                ...state,
                contratos: {
                    ...state.contratos,
                    codigo_contrato: action.payload
                }
            }
        case LOADING_CONTRATOS:
            return {
                ...state,
                contratos: {
                    ...state.contratos,
                    loading: true,
                    codigo_contrato: null
                },
                contrato_dados: {
                    ...contratosDefault.contrato_dados
                },
                contrato_coberturas: {
                    ...contratosDefault.contrato_coberturas
                },
                arquivosSinistralidade: {
                    ...contratosDefault.arquivosSinistralidade
                },
                arquivosContratos: {
                    ...contratosDefault.arquivosContratos
                }
            }
        case CONTRATO_NOT_FOUND:
            return {
                ...state,
                contratos: {
                    ...state.contratos,
                    codigo_unidade: action.payload.codigo_unidade,
                    lista: null,
                    loading: false,
                    codigo_contrato: null
                },
                contrato_dados: {
                    ...contratosDefault.contrato_dados
                },
                contrato_coberturas: {
                    ...contratosDefault.contrato_coberturas
                },
                arquivosSinistralidade: {
                    ...contratosDefault.arquivosSinistralidade
                },
                arquivosContratos: {
                    ...contratosDefault.arquivosContratos
                }
            }

        case GET_DADOS_CONTRATOS:
            return {
                ...state,
                contrato_dados: {
                    ...state.contrato_dados,
                    dados: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                },
            }
        case DADOS_CONTRATO_NOT_FOUND:
            return {
                ...state,
                contrato_dados: {
                    ...state.contrato_dados,
                    dados: null,
                    loading: false
                }
            }
        case LOADING_DADOS_CONTRATOS:
            return {
                ...state,
                contrato_dados: {
                    ...state.contrato_dados,
                    dados: null,
                    loading: true
                }
            }

        case GET_CONTRATOS_COBERTURAS:
            return {
                ...state,
                contrato_coberturas: {
                    ...state.contrato_coberturas,
                    dados: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case CONTRATOS_COBERTURAS_NOT_FOUND:
            return {
                ...state,
                contrato_coberturas: {
                    ...state.contrato_coberturas,
                    dados: null,
                    loading: false
                }
            }
        case LOADING_CONTRATO_COBERTURAS:
            return {
                ...state,
                contrato_coberturas: {
                    ...state.contrato_coberturas,
                    dados: null,
                    loading: true
                }
            }

        case GET_ARQUIVOS_SINISTRALIDADE:
            return {
                ...state,
                arquivosSinistralidade: {
                    ...state.arquivosSinistralidade,
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    tipo_contrato: action.payload.tipo_contrato,
                    loading: false
                }
            }
        case ARQUIVOS_SINISTRALIDADE_NOT_FOUND:
            return {
                ...state,
                arquivosSinistralidade: {
                    ...state.arquivosSinistralidade,
                    data: null,
                    tipo_contrato: action.payload.tipo_contrato,
                    loading: false
                }
            }
        case LOADING_ARQUIVOS_SINISTRALIDADE:
            return {
                ...state,
                arquivosSinistralidade: {
                    ...state.arquivosSinistralidade,
                    data: null,
                    loading: true
                }
            }

        case GET_ARQUIVOS_CONTRATOS:
            return {
                ...state,
                arquivosContratos: {
                    ...state.arquivosContratos,
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    codigo_area: action.payload.codigo_area,
                    loading: false
                }
            }
        case ARQUIVOS_CONTRATOS_NOT_FOUND:
            return {
                ...state,
                arquivosContratos: {
                    ...state.arquivosContratos,
                    data: null,
                    loading: false
                }
            }
        case LOADING_ARQUIVOS_CONTRATOS:
            return {
                ...state,
                arquivosContratos: {
                    ...state.arquivosContratos,
                    data: null,
                    loading: true
                }
            }

        default: return state
    }
}

export default contratosReducer;