import {
    GET_SEGURADOS,
    LOADING_SEGURADOS,
    SEGURADOS_NOT_FOUND,
    SELECIONAR_SEGURADO,

    GET_VERBAS_ADICIONAIS,
    LOADING_VERBAS_ADICIONAIS,
    VERBAS_ADICIONAIS_NOT_FOUND,

    GET_CAMPOS_ADICIONAIS,
    LOADING_CAMPOS_ADICIONAIS,
    CAMPOS_ADICIONAIS_NOT_FOUND,

    GET_PLANOS_VALORES,
    LOADING_PLANOS_VALORES,
    PLANOS_VALORES_NOT_FOUND

} from './types';
import {
    GET_CONTRATOS
} from '../contratos/types';
import { LOGOUT } from '../auth/types';

export const seguradosDefault = {
    segurados: {
        data: null,
        codigo_contrato: null,
        dados: null,
        loading: false
    },
    verbasAdicionais: {
        data: null,
        codigo_segurado: null,
        loading: false
    },
    camposAdicionais: {
        data: null,
        codigo_segurado: null,
        loading: false
    },
    planosValores: {
        data: null,
        codigo_segurado: null,
        loading: false
    }
}

const seguradosReducer = (state = seguradosDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return seguradosDefault;
        case GET_CONTRATOS:
            return seguradosDefault;

        case GET_SEGURADOS:
            const primeiroAtivo = action.payload.data ? action.payload.data.filter(item => item.ativo_inativo === 'A')[0] : null;
            return {
                ...state,
                segurados: {
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    dados: primeiroAtivo,
                    loading: false
                }
            }
        case SELECIONAR_SEGURADO:
            return {
                ...state,
                segurados: {
                    ...state.segurados,
                    dados: action.payload
                }
            }
        case LOADING_SEGURADOS:
            return {
                ...state,
                segurados: {
                    data: null,
                    codigo_contrato: null,
                    dados: null,
                    loading: true
                },
                verbasAdicionais: { ...seguradosDefault.verbasAdicionais },
                camposAdicionais: { ...seguradosDefault.camposAdicionais },
                planosValores: { ...seguradosDefault.planosValores }
            }
        case SEGURADOS_NOT_FOUND:
            return {
                ...state,
                segurados: {
                    ...state.segurados,
                    data: null,
                    dados: null,
                    loading: false
                },
                verbasAdicionais: { ...seguradosDefault.verbasAdicionais },
                camposAdicionais: { ...seguradosDefault.camposAdicionais },
                planosValores: { ...seguradosDefault.planosValores }
            }

        case GET_VERBAS_ADICIONAIS:
            return {
                ...state,
                verbasAdicionais: {
                    data: action.payload.data,
                    codigo_segurado: action.payload.codigo_segurado,
                    loading: false
                }
            }
        case LOADING_VERBAS_ADICIONAIS:
            return {
                ...state,
                verbasAdicionais: {
                    ...state.verbasAdicionais,
                    loading: true
                }
            }
        case VERBAS_ADICIONAIS_NOT_FOUND:
            return {
                ...state,
                verbasAdicionais: {
                    ...state.verbasAdicionais,
                    data: null,
                    loading: false
                }
            }

        case GET_CAMPOS_ADICIONAIS:
            return {
                ...state,
                camposAdicionais: {
                    data: action.payload.data,
                    codigo_segurado: action.payload.codigo_segurado,
                    loading: false
                }
            }
        case LOADING_CAMPOS_ADICIONAIS:
            return {
                ...state,
                camposAdicionais: {
                    ...state.camposAdicionais,
                    loading: true
                }
            }
        case CAMPOS_ADICIONAIS_NOT_FOUND:
            return {
                ...state,
                camposAdicionais: {
                    ...state.camposAdicionais,
                    data: null,
                    loading: false
                }
            }

        case GET_PLANOS_VALORES:
            return {
                ...state,
                planosValores: {
                    data: action.payload.data,
                    codigo_segurado: action.payload.codigo_segurado,
                    loading: false
                }
            }
        case LOADING_PLANOS_VALORES:
            return {
                ...state,
                planosValores: {
                    ...state.planosValores,
                    loading: true
                }
            }
        case PLANOS_VALORES_NOT_FOUND:
            return {
                ...state,
                planosValores: {
                    ...state.planosValores,
                    data: null,
                    loading: false
                }
            }


        default: return state
    }
}

export default seguradosReducer;