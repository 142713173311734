import { useContext, createContext } from 'react';
import { authDefault } from '../reducers/auth';
import { corretoraDefault } from '../reducers/corretora';
import { unidadesDefault } from '../reducers/unidades';
import { contratosDefault } from '../reducers/contratos';
import { tabelasDefault } from '../reducers/tabelas';
import { seguradosDefault } from '../reducers/segurados';
import { faturasDefault } from '../reducers/faturas';
import { processosDefault } from '../reducers/processos';
import { dashboardDefault } from '../reducers/dashboard';
import { atendimentosDefault } from '../reducers/atendimentos';
import { movimentosDefault } from '../reducers/movimentos';
import { themeDefault } from '../reducers/theme';
import { arquivosDefault } from '../reducers/arquivos';
import { regrasVidaDefault } from '../reducers/regrasVida';

export const defaultStore = {
    store: { 
        ...authDefault, 
        ...corretoraDefault,
        ...unidadesDefault,
        ...contratosDefault,
        ...tabelasDefault,
        ...seguradosDefault,
        ...faturasDefault,
        ...processosDefault,
        ...dashboardDefault,
        ...atendimentosDefault,
        ...movimentosDefault,
        ...themeDefault,
        ...arquivosDefault,
        ...regrasVidaDefault
    },
    dispatch: () => {}
}

export const StoreContext = createContext(defaultStore);
const UseCode = () => {
    return useContext(StoreContext);
}

export default UseCode;