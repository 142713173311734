import React, { useEffect } from 'react';
import Menu from '../Menu';

const Main = props => {

    const { children } = props;

    useEffect(() => {
        const isFluid = JSON.parse(localStorage.getItem('isFluid'));
        if (isFluid) {
            const container = document.querySelector('[data-layout]');
            if (container) {
                container.classList.remove('container');
                container.classList.add('container-fluid');
            }
        }        
    });

    return (
        <main className="main" id="top">
            <div className="container" data-layout="container">
                <Menu />
                <div className="content">
                    {children}
                    <footer>
                        <div className="row g-0 justify-content-center fs--1 mt-3 mb-3">
                            <div className="col-12 col-sm-auto text-center me-1">
                                <p className="mb-0 text-600">Obrigado por usar mais um produto Siscor <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> 2021 &copy; <a href="https://siscor.com.br">Siscor</a></p>
                            </div>
                            <div className="col-12 col-sm-auto text-center">
                                <p className="mb-0 text-600"><span className="d-none d-sm-inline-block">| </span> v1.0.0</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
            
        </main>
    );
};

export default Main;
