import { useEffect } from 'react';
import { lottieInit } from '../lib/function';

const Loading = props => {

    useEffect(() => {
        lottieInit();
    });

    return (
        <div
            className="d-flex justify-content-center align-items-center flex-column"
            style={{ width: '100vw', height: '100vh' }}
        >
            <div className="navbar-brand">
                <div className="d-flex align-items-center" style={{ display: 'flex', flexDirection: 'row', alignItems: "center" }}>
                    <img src="assets/img/logos/Logo-Color2.png" alt="Logo Cliente" height={30} style={{ marginRight: 10 }} />
                    <span className="font-sans-serif text-primary fs-4">Portal do RH</span>
                </div>
            </div>
            <div
                className="lottie mx-auto0"
                style={{ width: '400px', height: '400px', margin: '-80px' }}
                data-options='{"path":"https://assets1.lottiefiles.com/packages/lf20_iitdh6nn.json"}'
            ></div>
            <h2 className="p-0 mt-2 text-primary">carregando...</h2>
        </div>
    );
}

export default Loading;