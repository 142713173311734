import {
    GET_VIGENCIAS,
    SET_VIGENCIA,
    LOADING_VIGENCIA,
    VIGENCIAS_NOT_FOUND,

    GET_TABELAS,
    TABELAS_NOT_FOUND,
    LOADING_TABELA,

    GET_ALTERACOES,
    ALTERACOES_NOT_FOUND,
    LOADING_ALTERACOES,

    GET_APORTES,
    APORTES_NOT_FOUND,
    LOADING_APORTES,

    GET_EXEMPLOS_REEMBOLSO,
    EXEMPLOS_REEMBOLSO_NOT_FOUND,
    LOADING_EXEMPLOS_REEMBOLSO

} from './types';
import { LOGOUT } from '../auth/types';

export const tabelasDefault = {
    vigencias: {
        lista: null,
        codigo_vigencia: null,
        codigo_contrato: null,
        loading: false
    },
    tabelas: {
        data: null,
        tipo_tabela: null,
        codigo_vigencia: null,
        loading: false
    },
    alteracoes: {
        data: null,
        codigo_contrato: null,
        loading: false
    },
    aportes: {
        data: null,
        codigo_contrato: null,
        loading: false
    },
    exmeplosReembolso: {
        data: null,
        codigo_contrato: null,
        loading: false
    }
}

const tabelasReducer = (state = tabelasDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return tabelasDefault

        case GET_VIGENCIAS:
            return {
                ...state,
                vigencias: {
                    ...state.vigencias,
                    lista: action.payload.data,
                    codigo_vigencia: action.payload.data.length > 0 ? action.payload.data[0].cod_contrato_produto : null,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case SET_VIGENCIA:
            return {
                ...state,
                vigencias: {
                    ...state.vigencias,
                    codigo_vigencia: action.payload
                }
            }
        case LOADING_VIGENCIA:
            return {
                ...state,
                vigencias: {
                    ...state.vigencias,
                    lista: null,
                    loading: true
                },
                tabelas: {
                    data: null,
                    codigo_vigencia: null,
                    loading: false
                }
            }
        case VIGENCIAS_NOT_FOUND:
            return {
                ...state,
                vigencias: {
                    lista: null,
                    codigo_vigencia: null,
                }
            }

        case GET_TABELAS:
            return {
                ...state,
                tabelas: {
                    ...state.tabelas,
                    data: action.payload.data,
                    tipo_tabela: action.payload.tipo_tabela,
                    codigo_vigencia: action.payload.codigo_vigencia,
                    loading: false
                }
            }
        case LOADING_TABELA:
            return {
                ...state,
                tabelas: {
                    ...state.tabelas,
                    data: null,
                    loading: true
                }
            }
        case TABELAS_NOT_FOUND:
            return {
                ...state,
                tabelas: {
                    data: null,
                    tipo_tabela: null,
                    codigo_vigencia: null,
                    loading: false
                }
            }

        case GET_ALTERACOES:
            return {
                ...state,
                alteracoes: {
                    ...state.alteracoes,
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case LOADING_ALTERACOES:
            return {
                ...state,
                alteracoes: {
                    ...state.alteracoes,
                    data: null,
                    loading: true
                }
            }
        case ALTERACOES_NOT_FOUND:
            return {
                ...state,
                alteracoes: {
                    data: null,
                    loading: false
                }
            }

        case GET_APORTES:
            return {
                ...state,
                aportes: {
                    ...state.aportes,
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case LOADING_APORTES:
            return {
                ...state,
                aportes: {
                    ...state.aportes,
                    data: null,
                    loading: true
                }
            }
        case APORTES_NOT_FOUND:
            return {
                ...state,
                aportes: {
                    data: null,
                    loading: false
                }
            }

        case GET_EXEMPLOS_REEMBOLSO:
            return {
                ...state,
                exmeplosReembolso: {
                    ...state.exmeplosReembolso,
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case LOADING_EXEMPLOS_REEMBOLSO:
            return {
                ...state,
                exmeplosReembolso: {
                    ...state.exmeplosReembolso,
                    data: null,
                    loading: true
                }
            }
        case EXEMPLOS_REEMBOLSO_NOT_FOUND:
            return {
                ...state,
                exmeplosReembolso: {
                    data: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default tabelasReducer;