const GET_DADOS_SEGUROS = 'GET_DADOS_SEGUROS';
const LOADING_DADOS_SEGUROS = 'LOADING_DADOS_SEGUROS';
const DADOS_SEGUROS_NOT_FOUND = 'DADOS_SEGUROS_NOT_FOUND';

const GET_SEXO_RAMO = 'GET_SEXO_RAMO';
const LOADING_SEXO_RAMO = 'LOADING_SEXO_RAMO';
const SEXO_RAMO_NOT_FOUND = 'SEXO_RAMO_NOT_FOUND';

const GET_DISTRIBUICAO = 'GET_DISTRIBUICAO';
const LOADING_DISTRIBUICAO = 'LOADING_DISTRIBUICAO';
const DISTRIBUICAO_NOT_FOUND = 'DISTRIBUICAO_NOT_FOUND';

const GET_PARENTESCO_RAMO = 'GET_PARENTESCO_RAMO';
const LOADING_PARENTESCO_RAMO = 'LOADING_PARENTESCO_RAMO';
const PARENTESCO_RAMO_NOT_FOUND = 'PARENTESCO_RAMO_NOT_FOUND';

const GET_CONTRATOS_DASHBOARD = 'GET_CONTRATOS_DASHBOARD';
const LOADING_CONTRATOS_DASHBOARD = 'LOADING_CONTRATOS_DASHBOARD';
const CONTRATOS_DASHBOARD_NOT_FOUND = 'CONTRATOS_DASHBOARD_NOT_FOUND';

const GET_LINKS_CORRETORA = 'GET_LINKS_CORRETORA';
const LOADING_LINKS_CORRETORA = 'LOADING_LINKS_CORRETORA';
const LINKS_CORRETORA_NOT_FOUND = 'LINKS_CORRETORA_NOT_FOUND';

const SELECIONAR_TIPO_CONTRATO = 'SELECIONAR_TIPO_CONTRATO';
const SELECIONAR_STATUS_CONTRATO = 'SELECIONAR_STATUS_CONTRATO';

export {
    GET_DADOS_SEGUROS,
    LOADING_DADOS_SEGUROS,
    DADOS_SEGUROS_NOT_FOUND,
    SELECIONAR_TIPO_CONTRATO,
    SELECIONAR_STATUS_CONTRATO,

    GET_SEXO_RAMO,
    LOADING_SEXO_RAMO,
    SEXO_RAMO_NOT_FOUND,

    GET_DISTRIBUICAO,
    LOADING_DISTRIBUICAO,
    DISTRIBUICAO_NOT_FOUND,

    GET_PARENTESCO_RAMO,
    LOADING_PARENTESCO_RAMO,
    PARENTESCO_RAMO_NOT_FOUND,

    GET_CONTRATOS_DASHBOARD,
    LOADING_CONTRATOS_DASHBOARD,
    CONTRATOS_DASHBOARD_NOT_FOUND,

    GET_LINKS_CORRETORA,
    LOADING_LINKS_CORRETORA,
    LINKS_CORRETORA_NOT_FOUND
}