import {
    GET_DADOS_SEGUROS,
    LOADING_DADOS_SEGUROS,
    DADOS_SEGUROS_NOT_FOUND,
    SELECIONAR_TIPO_CONTRATO,
    SELECIONAR_STATUS_CONTRATO,

    GET_SEXO_RAMO,
    LOADING_SEXO_RAMO,
    SEXO_RAMO_NOT_FOUND,

    GET_DISTRIBUICAO,
    LOADING_DISTRIBUICAO,
    DISTRIBUICAO_NOT_FOUND,

    GET_PARENTESCO_RAMO,
    LOADING_PARENTESCO_RAMO,
    PARENTESCO_RAMO_NOT_FOUND,

    GET_CONTRATOS_DASHBOARD,
    LOADING_CONTRATOS_DASHBOARD,
    CONTRATOS_DASHBOARD_NOT_FOUND,

    GET_LINKS_CORRETORA,
    LOADING_LINKS_CORRETORA,
    LINKS_CORRETORA_NOT_FOUND
} from './types';
import { LOGOUT } from '../auth/types';

export const dashboardDefault = {
    seguros: {
        data: null,
        tipo_contrato: null,
        status_contrato: 'A',
        loading: false
    },
    sexoRamo: {
        data: null,
        status_contrato: null,
        loading: false
    },
    distribuicaoFaixaEtaria: {
        data: null,
        loading: false,
        tipo_contrato: null,
        status_contrato: null,
    },
    parentescoRamo: {
        data: null,
        loading: false,
        codigo_unidade: null,
        status_contrato: null
    },
    contratosDashboard: {
        data: null,
        loading: false
    },
    linksCorretora: {
        data: null,
        loading: false
    }
}

const dashboardReducer = (state = dashboardDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return dashboardDefault;

        case GET_DADOS_SEGUROS:
            return {
                ...state,
                seguros: {
                    data: action.payload,
                    status_contrato: state.seguros.status_contrato,
                    loading: false
                }
            }
        case LOADING_DADOS_SEGUROS:
            return {
                ...state,
                seguros: {
                    ...state.seguros,
                    loading: true
                }
            }
        case DADOS_SEGUROS_NOT_FOUND:
            return {
                ...state,
                seguros: {
                    ...state.seguros,
                    data: null,
                    loading: false
                }
            }
        case SELECIONAR_TIPO_CONTRATO:
            return {
                ...state,
                seguros: {
                    ...state.seguros,
                    tipo_contrato: action.payload
                }
            }
        case SELECIONAR_STATUS_CONTRATO:
            return {
                ...state,
                seguros: {
                    ...state.seguros,
                    status_contrato: action.payload
                }
            }

        case GET_SEXO_RAMO:
            return {
                ...state,
                sexoRamo: {
                    data: action.payload,
                    status_contrato: action.status_contrato,
                    loading: false
                }
            }
        case LOADING_SEXO_RAMO:
            return {
                ...state,
                sexoRamo: {
                    ...state.sexoRamo,
                    loading: true
                }
            }
        case SEXO_RAMO_NOT_FOUND:
            return {
                ...state,
                sexoRamo: {
                    ...state.sexoRamo,
                    data: null,
                    status_contrato: action.status_contrato,
                    loading: false
                }
            }

        case GET_DISTRIBUICAO:
            return {
                ...state,
                distribuicaoFaixaEtaria: {
                    data: action.payload,
                    loading: false,
                    tipo_contrato: action.codigoTipoContrato,
                    status_contrato: action.status_contrato
                }
            }
        case LOADING_DISTRIBUICAO:
            return {
                ...state,
                distribuicaoFaixaEtaria: {
                    ...state.distribuicaoFaixaEtaria,
                    loading: true
                }
            }
        case DISTRIBUICAO_NOT_FOUND:
            return {
                ...state,
                distribuicaoFaixaEtaria: {
                    ...state.distribuicaoFaixaEtaria,
                    data: null,
                    loading: false,
                    tipo_contrato: action.codigoTipoContrato,
                    status_contrato: action.status_contrato
                }
            }

        case GET_PARENTESCO_RAMO:
            return {
                ...state,
                parentescoRamo: {
                    data: action.payload,
                    codigo_unidade: action.codigo_unidade,
                    status_contrato: action.status_contrato,
                    loading: false
                }
            }
        case LOADING_PARENTESCO_RAMO:
            return {
                ...state,
                parentescoRamo: {
                    ...state.parentescoRamo,
                    loading: true
                }
            }
        case PARENTESCO_RAMO_NOT_FOUND:
            return {
                ...state,
                parentescoRamo: {
                    ...state.parentescoRamo,
                    data: null,
                    loading: false,
                    codigo_unidade: action.codigo_unidade,
                    status_contrato: action.status_contrato
                }
            }

        case GET_CONTRATOS_DASHBOARD:
            return {
                ...state,
                contratosDashboard: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_CONTRATOS_DASHBOARD:
            return {
                ...state,
                contratosDashboard: {
                    ...state.contratosDashboard,
                    loading: true
                }
            }
        case CONTRATOS_DASHBOARD_NOT_FOUND:
            return {
                ...state,
                contratosDashboard: {
                    ...state.contratosDashboard,
                    data: null,
                    loading: false
                }
            }

        case GET_LINKS_CORRETORA:
            return {
                ...state,
                linksCorretora: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_LINKS_CORRETORA:
            return {
                ...state,
                linksCorretora: {
                    ...state.linksCorretora,
                    loading: true
                }
            }
        case LINKS_CORRETORA_NOT_FOUND:
            return {
                ...state,
                linksCorretora: {
                    ...state.linksCorretora,
                    data: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default dashboardReducer;