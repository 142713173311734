const GET_MESES_FATURAS = 'GET_MESES_FATURAS';
const LOADING_MESES_FATURAS = 'LOADING_MESES_FATURAS';
const MESES_FATURAS_NOT_FOUND = 'MESES_FATURAS_NOT_FOUND';
const SELECIONAR_MES_FATURA = 'SELECIONAR_MES_FATURA';

const GET_LISTA_FATURAS = 'GET_LISTA_FATURAS';
const LOADING_LISTA_FATURAS = 'LOADING_LISTA_FATURAS';
const LISTA_FATURAS_NOT_FOUND = 'LISTA_FATURAS_NOT_FOUND';

const GET_RESUMO_FATURA = 'GET_RESUMO_FATURA';
const LOADING_RESUMO_FATURA = 'LOADING_RESUMO_FATURA';
const RESUMO_FATURA_NOT_FOUND = 'RESUMO_FATURA_NOT_FOUND';

const GET_MOVIMENTOS = 'GET_MOVIMENTOS';
const LOADING_MOVIMENTOS = 'LOADING_MOVIMENTOS';
const MOVIMENTOS_NOT_FOUND = 'MOVIMENTOS_NOT_FOUND';

const GET_SEGUNDA_VIA = 'GET_SEGUNDA_VIA';
const LOADING_SEGUNDA_VIA = 'LOADING_SEGUNDA_VIA';
const SEGUNDA_VIA_NOT_FOUND = 'SEGUNDA_VIA_NOT_FOUND';

const GET_TAXA_IMPLANTACAO = 'GET_TAXA_IMPLANTACAO';
const LOADING_TAXA_IMPLANTACAO = 'LOADING_TAXA_IMPLANTACAO';
const TAXA_IMPLANTACAO_NOT_FOUND = 'TAXA_IMPLANTACAO_NOT_FOUND';

const GET_FATURA_VERBAS = 'GET_FATURA_VERBAS';
const LOADING_FATURA_VERBAS = 'LOADING_FATURA_VERBAS';
const FATURA_VERBAS_NOT_FOUND = 'FATURA_VERBAS_NOT_FOUND';

const GET_FATURA_ARQUIVOS = 'GET_FATURA_ARQUIVOS';
const LOADING_FATURA_ARQUIVOS = 'LOADING_FATURA_ARQUIVOS';
const FATURA_ARQUIVOS_NOT_FOUND = 'FATURA_ARQUIVOS_NOT_FOUND';

const GET_REAJUSTES = 'GET_REAJUSTES';
const LOADING_REAJUSTES = 'LOADING_REAJUSTES';
const REAJUSTES_NOT_FOUND = 'REAJUSTES_NOT_FOUND';

const GET_AJUSTES = 'GET_AJUSTES';
const LOADING_AJUSTES = 'LOADING_AJUSTES';
const AJUSTES_NOT_FOUND = 'AJUSTES_NOT_FOUND';

const GET_FATURA_BOLETOS = 'GET_FATURA_BOLETOS';
const LOADING_FATURA_BOLETOS = 'LOADING_FATURA_BOLETOS';
const FATURA_BOLETOS_NOT_FOUND = 'FATURA_BOLETOS_NOT_FOUND';

export {
    GET_MESES_FATURAS,
    LOADING_MESES_FATURAS,
    MESES_FATURAS_NOT_FOUND,
    SELECIONAR_MES_FATURA,

    GET_LISTA_FATURAS,
    LOADING_LISTA_FATURAS,
    LISTA_FATURAS_NOT_FOUND,    

    GET_RESUMO_FATURA,
    LOADING_RESUMO_FATURA,
    RESUMO_FATURA_NOT_FOUND,

    GET_MOVIMENTOS,
    LOADING_MOVIMENTOS,
    MOVIMENTOS_NOT_FOUND,

    GET_SEGUNDA_VIA,
    LOADING_SEGUNDA_VIA,
    SEGUNDA_VIA_NOT_FOUND,

    GET_TAXA_IMPLANTACAO,
    LOADING_TAXA_IMPLANTACAO,
    TAXA_IMPLANTACAO_NOT_FOUND,

    GET_FATURA_VERBAS,
    LOADING_FATURA_VERBAS,
    FATURA_VERBAS_NOT_FOUND,

    GET_FATURA_ARQUIVOS,
    LOADING_FATURA_ARQUIVOS,
    FATURA_ARQUIVOS_NOT_FOUND,

    GET_REAJUSTES,
    LOADING_REAJUSTES,
    REAJUSTES_NOT_FOUND,

    GET_AJUSTES,
    LOADING_AJUSTES,
    AJUSTES_NOT_FOUND,

    GET_FATURA_BOLETOS,
    LOADING_FATURA_BOLETOS,
    FATURA_BOLETOS_NOT_FOUND
}