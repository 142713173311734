const camelize = function camelize(str) {
    const text = str.replace(/[-_\s.]+(.)?/g, function (_, c) {
        return c ? c.toUpperCase() : '';
    });
    return "".concat(text.substr(0, 1).toLowerCase()).concat(text.substr(1));
};

const _defineProperty = (obj, key, value) => {
    if (key in obj) {
        Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true });
    } else {
        obj[key] = value;
    } return obj;
}

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

const ownKeys = (object, enumerableOnly) => {
    const keys = Object.keys(object);

    if (Object.getOwnPropertySymbols) {
        let symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function (sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }

    return keys;
}

const getData = function getData(el, data) {
    try {
        return JSON.parse(el.dataset[camelize(data)]);
    } catch (e) {
        return el.dataset[camelize(data)];
    }
};

function _objectSpread(target) {
    for (let i = 1; i < arguments.length; i++) {
        const source = arguments[i] != null ? arguments[i] : {};
        if (i % 2) {
            ownKeys(Object(source), true)
                .forEach(function (key) {
                    _defineProperty(target, key, source[key]);
                });
        } else if (Object.getOwnPropertyDescriptors) {
            Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
        } else {
            ownKeys(Object(source)).forEach(function (key) {
                Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
            });
        }
    } return target;
}

const validScreen = () => {
    return window.screen.width <= 1366;
}

const lottieInit = function lottieInit() {
    try {
        const lotties = document.querySelectorAll('.lottie');
        if (lotties.length) {
            lotties.forEach(function (item) {
                if (item.innerHTML === '') {
                    const options = getData(item, 'options');
                    window.bodymovin.loadAnimation(_objectSpread({
                        container: item,
                        path: '../img/animated-icons/warning-light.json',
                        renderer: 'svg',
                        loop: true,
                        autoplay: true,
                        name: 'Hello World'
                    }, options));
                }
            });
        }
    } catch { }
};

const convertObjToArray = data => {
    const newValue = data ? data.map(r => Object.values(r)) : null;
    return newValue;
}

const pivotTable = (dataArray, rowIndex, colIndex, dataIndex, refName) => {

    /*
    rowIndex: Valor da Primeira Coluna
    colIndex: Formação da Colunas (Titulos do Head)
    dataIndex: Valores a serem distribuidos
    refName: Nome da Primeira Coluna

    const output = pivotTable(arr, 0, 1, 2, 'Item');
    */

    const result = {}
    const head = [];
    const body = [];
    const newCols = [];

    for (let i = 0; i < dataArray.length; i++) {

        if (!result[dataArray[i][rowIndex]]) {
            result[dataArray[i][rowIndex]] = {};
        }
        result[dataArray[i][rowIndex]][dataArray[i][colIndex]] = dataArray[i][dataIndex];

        //Montar Nome de Colunas
        if (newCols.indexOf(dataArray[i][colIndex]) === -1) {
            newCols.push(dataArray[i][colIndex]);
        }
    }

    //newCols.sort();
    let item = [];

    //Criar Linha do Head
    item.push(refName);
    item.push.apply(item, newCols);
    head.push(item);

    //Montar Linha do Body
    for (let key in result) {
        item = [];
        item.push(key);
        for (let i = 0; i < newCols.length; i++) {
            item.push(result[key][newCols[i]] || "-");
        }
        body.push(item);
    }

    const table = [
        ...head,
        ...body.sort((a, b) => {
            if (a[0] > b[0]) return 1;
            if (a[0] < b[0]) return -1;
            return 0;
        })
    ]

    return table;
}

const getGrays = dom => {
    return {
        white: getColor('white', dom),
        100: getColor('100', dom),
        200: getColor('200', dom),
        300: getColor('300', dom),
        400: getColor('400', dom),
        500: getColor('500', dom),
        600: getColor('600', dom),
        700: getColor('700', dom),
        800: getColor('800', dom),
        900: getColor('900', dom),
        1000: getColor('1000', dom),
        1100: getColor('1100', dom),
        black: getColor('black', dom)
    };
};

function getColor(name) {
    var dom = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : document.documentElement;
    return getComputedStyle(dom).getPropertyValue("--falcon-".concat(name)).trim();
};

const getColors = dom => {
    return {
        primary: getColor('primary', dom),
        secondary: getColor('secondary', dom),
        success: getColor('success', dom),
        info: getColor('info', dom),
        warning: getColor('warning', dom),
        danger: getColor('danger', dom),
        light: getColor('light', dom),
        dark: getColor('dark', dom)
    };
};

const getPosition = (pos, params, dom, rect, size) => {
    return {
        top: pos[1] - size.contentSize[1] - 10,
        left: pos[0] - size.contentSize[0] / 2
    };
};

function rgbaColor() {
    var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '#fff';
    var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.5;
    return "rgba(".concat(hexToRgb(color), ", ").concat(alpha, ")");
};

const hexToRgb = hexValue => {
    var hex;
    hexValue.indexOf('#') === 0 ? hex = hexValue.substring(1) : hex = hexValue;

    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    }));
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};

const echartSetOption = (chart, userOptions, getDefaultOptions) => {
    var themeController = document.body;

    chart.setOption(window._.merge(getDefaultOptions(), userOptions));
    themeController.addEventListener('clickControl', function (_ref16) {
        var control = _ref16.detail.control;

        if (control === 'theme') {
            chart.setOption(window._.merge(getDefaultOptions(), userOptions));
        }
    });
};

const totalOrder = (id, dataGraph) => {

    var $echartLineTotalOrder = document.getElementById(id);

    if ($echartLineTotalOrder) {
        // Get options from data attribute
        var userOptions = getData($echartLineTotalOrder, 'options');
        var chart = window.echarts.init($echartLineTotalOrder); // Default options

        var getDefaultOptions = function getDefaultOptions() {
            return {
                tooltip: {
                    show: false,
                    triggerOn: 'mousemove',
                    trigger: 'axis',
                    padding: [7, 10],
                    formatter: '{b0}: {c0}',
                    backgroundColor: getGrays()['100'],
                    borderColor: getGrays()['300'],
                    textStyle: {
                        color: getColors().dark
                    },
                    borderWidth: 1,
                    transitionDuration: 0,
                    position: function position(pos, params, dom, rect, size) {
                        return getPosition(pos, params, dom, rect, size);
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [...dataGraph.xAxisValues],
                    boundaryGap: false,
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: getGrays()['300'],
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisPointer: {
                        type: 'none'
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisPointer: {
                        show: false
                    }
                },
                series: [{
                    type: 'line',
                    lineStyle: {
                        color: getColors().primary,
                        width: 3
                    },
                    itemStyle: {
                        color: getGrays().white,
                        borderColor: getColors().primary,
                        borderWidth: 2
                    },
                    hoverAnimation: true,
                    data: [...dataGraph.yAxisValues],
                    connectNulls: true,
                    smooth: 0.6,
                    smoothMonotone: 'x',
                    symbol: 'circle',
                    symbolSize: 8,
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: rgbaColor(getColors().primary, 0.25)
                            }, {
                                offset: 1,
                                color: rgbaColor(getColors().primary, 0)
                            }]
                        }
                    }
                }],
                grid: {
                    bottom: '5%',
                    top: '5%',
                    right: '10px',
                    left: '10px'
                }
            };
        };

        echartSetOption(chart, userOptions, getDefaultOptions);
    }
};

const countupInit = () => {
    if (window.countUp) {
        var countups = document.querySelectorAll('[data-countup]');
        countups.forEach(function (node) {
            var _utils$getData = getData(node, 'countup'),
                endValue = _utils$getData.endValue,
                options = _objectWithoutProperties(_utils$getData, ["endValue"]);

            var countUp = new window.countUp.CountUp(node, endValue, _objectSpread({
                duration: 5
            }, options));

            if (!countUp.error) {
                countUp.start();
            } else {
                console.error(countUp.error);
            }
        });
    }
};

const segurosGrafico = (id, dataGraph) => {

    try {
        var $echartMarketShare = document.getElementById(id);

        if ($echartMarketShare) {
            var userOptions = getData($echartMarketShare, 'options');
            var chart = window.echarts.init($echartMarketShare);

            var getDefaultOptions = function getDefaultOptions() {

                const newContent = dataGraph.map(item => {
                    return (
                        item.name[0] === 'S' ? getColors().primary :
                            item.name[0] === 'O' ? getColors().info :
                                item.name[0] === 'V' ? getColors().warning : getGrays()[300]
                    )
                });

                return {
                    color: [...newContent, getGrays()[300]],
                    tooltip: {
                        trigger: 'item',
                        padding: [7, 10],
                        backgroundColor: getGrays()['100'],
                        borderColor: getGrays()['300'],
                        textStyle: { color: getColors().dark },
                        borderWidth: 1,
                        transitionDuration: 0,
                        formatter: function formatter(params) {
                            return "<strong>".concat(params.data.name, ":</strong> ").concat(params.percent, "%");
                        }
                    },
                    position: function position(pos, params, dom, rect, size) {
                        return getPosition(pos, params, dom, rect, size);
                    },
                    legend: {
                        show: false
                    },
                    series: [{
                        type: 'pie',
                        radius: ['100%', '75%'],
                        avoidLabelOverlap: false,
                        hoverAnimation: false,
                        itemStyle: {
                            borderWidth: 1,
                            borderColor: getColor('card-bg')
                        },
                        label: {
                            normal: {
                                show: false,
                                position: 'center',
                                textStyle: {
                                    fontSize: '20',
                                    fontWeight: '500',
                                    color: getGrays()['700']
                                }
                            },
                            emphasis: {
                                show: false
                            }
                        },
                        labelLine: {
                            normal: {
                                show: false
                            }
                        },
                        data: dataGraph
                    }]
                };
            };
            echartSetOption(chart, userOptions, getDefaultOptions);
        }
    } catch { }
};

let arrayCharts = [];

const porRamoGrafico = (id, dataGraph) => {
    try {

        var $echartBarSexoPorRamo = document.getElementById(id);

        if ($echartBarSexoPorRamo) {

            var data = dataGraph;
            var userOptions = getData($echartBarSexoPorRamo, 'options');
            var chart = window.echarts.init($echartBarSexoPorRamo);

            const objChart = {
                name: id,
                object: chart
            }

            arrayCharts = [...arrayCharts.filter(item => item.name !==id), objChart];

            var getDefaultOptions = function getDefaultOptions() {

                const newContent = dataGraph[0].filter((_, i) => i > 0).map(item => {
                    return (
                        item[0] === 'S' ? getColors().primary :
                            item[0] === 'O' ? getColors().info :
                                item[0] === 'V' ? getColors().warning : getGrays()[300]
                    )
                });

                return {
                    color: [...newContent, getGrays()['300']],
                    dataset: {
                        source: data
                    },
                    tooltip: {
                        trigger: 'item',
                        padding: [7, 10],
                        backgroundColor: getGrays()['100'],
                        borderColor: getGrays()['300'],
                        textStyle: {
                            color: getColors().dark
                        },
                        borderWidth: 1,
                        transitionDuration: 0,
                        position: function position(pos, params, dom, rect, size) {
                            return getPosition(pos, params, dom, rect, size);
                        },
                        formatter: function formatter(params) {
                            return "<div class=\"font-weight-semi-bold\">".concat(params.seriesName, "</div><div class=\"fs--1 text-600\"><strong>").concat(params.name, ":</strong> ").concat(params.value[params.componentIndex + 1], "</div>");
                        }
                    },
                    legend: {
                        data: data[0],
                        left: 'left',
                        itemWidth: 10,
                        itemHeight: 10,
                        borderRadius: 0,
                        icon: 'circle',
                        inactiveColor: getGrays()['400'],
                        textStyle: {
                            color: getGrays()['700']
                        }
                    },
                    xAxis: {
                        type: 'category',
                        axisLabel: {
                            color: getGrays()['400']
                        },
                        axisLine: {
                            lineStyle: {
                                color: getGrays()['300'],
                                type: 'dashed'
                            }
                        },
                        axisTick: false,
                        boundaryGap: true
                    },
                    yAxis: {
                        axisPointer: {
                            type: 'none'
                        },
                        axisTick: 'none',
                        splitLine: {
                            lineStyle: {
                                color: getGrays()['300'],
                                type: 'dashed'
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        axisLabel: {
                            color: getGrays()['400']
                        }
                    },
                    series: [
                        ...dataGraph[0].filter((_, i) => i > 0).map(item => {
                            return ({
                                type: 'bar',
                                barWidth: '10%',
                                barGap: '30%',
                                label: {
                                    normal: {
                                        show: false
                                    }
                                },
                                z: 10,
                                itemStyle: {
                                    normal: {
                                        barBorderRadius: [10, 10, 0, 0],
                                        color: item[0] === 'S' ? getColors().primary :
                                            item[0] === 'O' ? getColors().info :
                                                item[0] === 'V' ? getColors().warning : getGrays()[300]
                                    }
                                }
                            })
                        })
                    ],
                    grid: {
                        right: '0',
                        left: '30px',
                        bottom: '10%',
                        top: '20%'
                    }
                };
            };

            echartSetOption(chart, userOptions, getDefaultOptions);
        }

        arrayCharts.forEach(chart => chart.object.resize());
        
    } catch { }
};

const distribuicaoGrafico = (id, dataGraph, tipo) => {
    try {
        var $echartsLineTotalSales = document.getElementById(id);

        const colorGrafico = [0, 1].includes(tipo) ? getColors().primary :
                             tipo === 2 ? getColors().info : 
                             tipo === 3 ? getColors().warning :  getColors().primary;

        if ($echartsLineTotalSales) {
            // Get options from data attribute
            var userOptions = getData($echartsLineTotalSales, 'options');
            var chart = window.echarts.init($echartsLineTotalSales);

            var getDefaultOptions = function getDefaultOptions() {
                return {
                    color: getGrays()['100'],
                    tooltip: {
                        trigger: 'axis',
                        padding: [7, 10],
                        backgroundColor: getGrays()['100'],
                        borderColor: getGrays()['300'],
                        textStyle: {
                            color: getColors().dark
                        },
                        borderWidth: 1,
                        formatter: ['0-18', '19-23', '24-28', '29-33', '34-38', '39-43', '44-48', '49-53', '54-58', '> 59'],
                        transitionDuration: 0,
                        position: function position(pos, params, dom, rect, size) {
                            return getPosition(pos, params, dom, rect, size);
                        }
                    },
                    xAxis: {
                        type: 'category',
                        data: ['0-18', '19-23', '24-28', '29-33', '34-38', '39-43', '44-48', '49-53', '54-58', '> 59'],
                        boundaryGap: false,
                        axisPointer: {
                            lineStyle: {
                                color: getGrays()['300'],
                                type: 'dashed'
                            }
                        },
                        splitLine: {
                            show: false
                        },
                        axisLine: {
                            lineStyle: {
                                color: rgbaColor('#000', 0.01),
                                type: 'dashed'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLabel: {
                            color: getGrays()['400'],
                            formatter: ['0-18', '19-23', '24-28', '29-33', '34-38', '39-43', '44-48', '49-53', '54-58', '> 59'],
                            margin: 15
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisPointer: {
                            show: false
                        },
                        splitLine: {
                            lineStyle: {
                                color: getGrays()['300'],
                                type: 'dashed'
                            }
                        },
                        boundaryGap: false,
                        axisLabel: {
                            show: true,
                            color: getGrays()['400'],
                            margin: 15
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        }
                    },
                    series: [{
                        type: 'line',
                        data: dataGraph,
                        lineStyle: {
                            color: colorGrafico
                        },
                        itemStyle: {
                            borderColor: colorGrafico,
                            borderWidth: 2
                        },
                        symbol: 'circle',
                        symbolSize: 10,
                        smooth: false,
                        hoverAnimation: true,
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0,
                                    color: rgbaColor(colorGrafico, 0.2)
                                }, {
                                    offset: 1,
                                    color: rgbaColor(colorGrafico, 0)
                                }]
                            }
                        }
                    }],
                    grid: {
                        right: '28px',
                        left: '40px',
                        bottom: '50px',
                        top: '5%',
                        height: '85%',
                        width: '90%'
                    }
                };
            };

            echartSetOption(chart, userOptions, getDefaultOptions); // Change chart options accordiong to the selected month
        }
    } catch { }
};

const getFileTipo = fileName => {
    const extensao = fileName.split('.').pop().toLowerCase();
    const fileTipo = extensao === 'zip' || extensao === 'rar' ? 'Z' :
        extensao === 'pdf' ? 'P' :
            extensao.indexOf('ppt') !== -1 ? 'O' :
                extensao.indexOf('doc') !== -1 ? 'W' :
                    extensao.indexOf('xls') !== -1 ? 'E' :
                        extensao.indexOf('jpg') !== -1 ||
                            extensao.indexOf('jpeg') !== -1 ||
                            extensao.indexOf('png') !== -1 ||
                            extensao.indexOf('gif') !== -1 ? 'I' : 'A';

    return fileTipo;
}

function GerarCode() {
    var n = Date.now();
    return n;
}

const serializeObject = obj => {
    const retorno = Object.entries(obj).map(item => `${item[0]}=${item[1]}`).join('&');
    return retorno;
}

const newChart = (chart, config) => {
    var ctx = chart.getContext('2d');
    return new window.Chart(ctx, config);
};

const settings = {
    tinymce: {
        theme: 'oxide'
    },
    chart: {
        borderColor: 'rgba(255, 255, 255, 0.8)'
    }
};

const chartLinePaymentInit = data => {

    /*-----------------------------------------------
    |   Helper functions and Data
    -----------------------------------------------*/
    const {chartData, labels, field} = data;
    const chartLine = document.getElementById(data.chartId);
    /*-----------------------------------------------
    |   Line Chart
    -----------------------------------------------*/

    if (chartLine) {

        var getChartBackground = function getChartBackground(chart) {
            var ctx = chart.getContext('2d');

            if (localStorage.getItem('theme') === 'light') {
                var _gradientFill = ctx.createLinearGradient(0, 0, 0, 250);

                _gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0.3)');

                _gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0)');

                return _gradientFill;
            }

            var gradientFill = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
            gradientFill.addColorStop(0, rgbaColor(getColors().primary, 0.5));
            gradientFill.addColorStop(1, 'transparent');
            return gradientFill;
        };

        var dashboardLineChart = newChart(chartLine, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    borderWidth: 2,
                    data: chartData,
                    borderColor: localStorage.getItem('theme') === 'dark' ? getColors().primary : settings.chart.borderColor,
                    backgroundColor: getChartBackground(chartLine)
                }]
            },
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    mode: 'x-axis',
                    xPadding: 20,
                    yPadding: 10,
                    displayColors: false,
                    callbacks: {
                        label: function label(tooltipItem) { 
                            const isDecimal = field ? field !== 'qtde_vidas' : false;
                            const label = isDecimal ? 'Valor: R$ ' : 'Qtde.: ';
                            const value = isDecimal ? tooltipItem.yLabel.toLocaleString('pt-BR', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : tooltipItem.yLabel;
                            return "".concat('Mês: ', labels[tooltipItem.index], " - ").concat(label, value);
                        },
                        title: function title() {
                            return null;
                        }
                    }
                },
                hover: {
                    mode: 'label'
                },
                scales: {
                    xAxes: [{
                        scaleLabel: {
                            show: true,
                            labelString: 'Month'
                        },
                        ticks: {
                            fontColor: rgbaColor('#fff', 0.7),
                            fontStyle: 600
                        },
                        gridLines: {
                            color: rgbaColor('#fff', 0.1),
                            zeroLineColor: rgbaColor('#fff', 0.1),
                            lineWidth: 1
                        }
                    }],
                    yAxes: [{
                        display: false
                    }]
                }
            }
        });

        var changeChartThemeColor = function changeChartThemeColor(borderColor) {
            dashboardLineChart.data.datasets[0].borderColor = borderColor;
            dashboardLineChart.data.datasets[0].backgroundColor = getChartBackground(chartLine);
            dashboardLineChart.update();
        }; // event trigger


        var themeController = document.body;
        themeController.addEventListener('clickControl', function (_ref14) {
            var _ref14$detail = _ref14.detail,
                control = _ref14$detail.control,
                value = _ref14$detail.value;

            if (control === 'theme') {
                if (value === 'dark') {
                    changeChartThemeColor(getColors().primary);
                } else {
                    changeChartThemeColor(settings.chart.borderColor);
                }
            }
        });
    }
};

const sortContratos = (a, b) => {
    if (a.tipo_contrato > b.tipo_contrato) return 1;
    if (a.tipo_contrato < b.tipo_contrato) return -1;
    if (a.unidade > b.unidade) return 1;
    if (a.unidade < b.unidade) return -1;
    return 0;
}

export {
    getData,
    _objectSpread,
    validScreen,
    lottieInit,
    convertObjToArray,
    pivotTable,
    totalOrder,
    countupInit,
    segurosGrafico,
    porRamoGrafico,
    distribuicaoGrafico,
    getFileTipo,
    GerarCode,
    serializeObject,
    chartLinePaymentInit,
    sortContratos
};