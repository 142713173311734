import {
    GET_ARQUIVOS_FORMULARIOS,
    LOADING_ARQUIVOS_FORMULARIOS,
    ARQUIVOS_FORMULARIOS_NOT_FOUND,

    GET_ARQUIVOS_PUBLICACOES,
    LOADING_ARQUIVOS_PUBLICACOES,
    ARQUIVOS_PUBLICACOES_NOT_FOUND
} from './types';

import {
    LOGOUT
} from '../auth/types';

export const arquivosDefault = {
    arquivosFormularios: {
        data: null,
        loading: false
    },
    arquivosPublicacoes: {
        data: null,
        loading: false
    }
}

const arquivosReducer = (state = arquivosDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return arquivosDefault;

        case GET_ARQUIVOS_FORMULARIOS:
            return {
                ...state,
                arquivosFormularios: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_ARQUIVOS_FORMULARIOS:
            return {
                ...state,
                arquivosFormularios: {
                    ...state.arquivosFormularios,
                    data: null,
                    loading: true
                }
            }
        case ARQUIVOS_FORMULARIOS_NOT_FOUND:
            return {
                ...state,
                arquivosFormularios: {
                    ...state.arquivosFormularios,
                    data: null,
                    loading: false
                }
            }

        case GET_ARQUIVOS_PUBLICACOES:
            return {
                ...state,
                arquivosPublicacoes: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_ARQUIVOS_PUBLICACOES:
            return {
                ...state,
                arquivosPublicacoes: {
                    ...state.arquivosPublicacoes,
                    data: null,
                    loading: true
                }
            }
        case ARQUIVOS_PUBLICACOES_NOT_FOUND:
            return {
                ...state,
                arquivosPublicacoes: {
                    ...state.arquivosPublicacoes,
                    data: null,
                    loading: false
                }
            }

        default:
            return state;
    }
}

export default arquivosReducer;