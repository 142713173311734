import React, {
    Suspense,
    Fragment,
    lazy
} from 'react';
import {
    Switch,
    Redirect,
    Route
} from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import Main from './layouts/Main';

export const renderRoutes = (routes = []) => (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
                const Guard = route.guard || Fragment;
                const Layout = route.layout || Fragment;
                const Component = route.component;

                return (
                    <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Guard>
                                <Layout>
                                    {route.routes
                                        ? renderRoutes(route.routes)
                                        : <Component {...props} />}
                                </Layout>
                            </Guard>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
);

const routes = [
    {
        exact: true,
        path: '/404',
        component: lazy(() => import('./views/errors/NotFoundView'))
    },
    {
        exact: true,
        path: '/login',
        component: lazy(() => import('./views/auth/login'))
    },
    {
        path: '/',
        guard: AuthGuard,
        layout: Main,
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./views/home'))
            },
            {
                exact: true,
                path: '/home',
                component: lazy(() => import('./views/home'))
            },
            {
                exact: true,
                path: '/contratos',
                component: lazy(() => import('./views/contratos'))
            },
            {
                exact: true,
                path: '/tabelas_valores',
                component: lazy(() => import('./views/tabelas'))
            },
            {
                exact: true,
                path: '/segurados',
                component: lazy(() => import('./views/segurados'))
            },
            {
                exact: true,
                path: '/faturas',
                component: lazy(() => import('./views/faturas'))
            },
            {
                exact: true,
                path: '/atendimentos',
                component: lazy(() => import('./views/atendimentos'))
            },            
            {
                exact: true,
                path: '/movimentacao',
                component: lazy(() => import('./views/movimentacao'))
            },                                
            {
                exact: true,
                path: '/arquivos',
                component: lazy(() => import('./views/arquivos'))
            },            
            {
                component: () => <Redirect to="/404" />
            }
        ]
    },
    {
        component: () => <Redirect to="/404" />
    }
];
export default routes;
