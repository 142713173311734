import { GET_CORRETORAS, NOT_FOUND } from './types';
import axios from '../../../lib/axios';

export const getDadosCorretora = idContato => {
    return new Promise(async resolve => {
        try {          
            const res = await axios.get(`atendimento/corretora/${idContato}`);       
            const data = res.data[0];                        
            if(res.data) {
                resolve({
                    type: GET_CORRETORAS, 
                    payload: data
                })
            } else {
                resolve({ type: NOT_FOUND });    
            }
        }
        catch {
            resolve({ type: NOT_FOUND });
        }
    });
}