const GET_VIGENCIAS_REGRAS = 'GET_VIGENCIAS_REGRAS';
const SET_VIGENCIA_REGRA = 'SET_VIGENCIA_REGRA';
const LOADING_VIGENCIA_REGRA = 'LOADING_VIGENCIA_REGRA';
const VIGENCIAS_REGRAS_NOT_FOUND = 'VIGENCIAS_REGRAS_NOT_FOUND';

const GET_REGRAS_VIDA = 'GET_REGRAS_VIDA'
const REGRAS_VIDA_NOT_FOUND = 'REGRAS_VIDA_NOT_FOUND';
const LOADING_REGRA_VIDA = 'LOADING_REGRA_VIDA';

export { 
    
    GET_VIGENCIAS_REGRAS,
    SET_VIGENCIA_REGRA,
    LOADING_VIGENCIA_REGRA,
    VIGENCIAS_REGRAS_NOT_FOUND,

    GET_REGRAS_VIDA,
    REGRAS_VIDA_NOT_FOUND,
    LOADING_REGRA_VIDA
    
};