import {
    GET_FILTRO_TIPO_MOVIMENTO,
    LOADING_FILTRO_TIPO_MOVIMENTO,
    FILTRO_TIPO_MOVIMENTO_NOT_FOUND,

    GET_FILTRO_SEGURADOS,
    LOADING_FILTRO_SEGURADOS,
    FILTRO_SEGURADOS_NOT_FOUND
} from './types';
import { LOGOUT } from '../auth/types';

export const movimentosDefault = {
    filtroTipoMovimento: {
        data: null,
        loading: false
    },
    filtroSegurados: {
        data: null,
        loading: false
    }
}

const movimentosReducer = (state = movimentosDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return movimentosDefault;

        case GET_FILTRO_TIPO_MOVIMENTO:
            return {
                ...state,
                filtroTipoMovimento: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_FILTRO_TIPO_MOVIMENTO:
            return {
                ...state,
                filtroTipoMovimento: {
                    ...state.filtroTipoMovimento,
                    data: null,
                    loading: true
                }
            }
        case FILTRO_TIPO_MOVIMENTO_NOT_FOUND:
            return {
                ...state,
                filtroTipoMovimento: {
                    ...state.filtroTipoMovimento,
                    data: null,
                    loading: false
                }
            }

            case GET_FILTRO_SEGURADOS:
                return {
                    ...state,
                    filtroSegurados: {
                        data: action.payload,
                        loading: false
                    }
                }
            case LOADING_FILTRO_SEGURADOS:
                return {
                    ...state,
                    filtroSegurados: {
                        ...state.filtroSegurados,
                        data: null,
                        loading: true
                    }
                }
            case FILTRO_SEGURADOS_NOT_FOUND:
                return {
                    ...state,
                    filtroSegurados: {
                        ...state.filtroSegurados,
                        data: null,
                        loading: false
                    }
                }

        default: return state;
    }
}

export default movimentosReducer;