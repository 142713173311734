import { 
    GET_PROCESSOS, 
    LOADING_PROCESSOS, 
    PROCESSOS_NOT_FOUND, 
    
    FILTRO_PROCESSOS, 
    LOADING_FILTROS, 
    FILTRO_NOT_FOUND,

    GET_PROCESSOS_RASTREIO,
    LOADING_PROCESSOS_RASTREIO,
    PROCESSOS_RASTREIO_NOT_FOUND

} from './types';
import { LOGOUT } from '../auth/types';

export const processosDefault = {
    processos: {
        data: null,
        loading: false,
        dataFinal: null
    },
    processoRastreio: {
        data: null,
        arquivos: null,
        loading: false,
        codigoProcesso: null
    }
}

const processosReducer = (state = processosDefault, action) => {
    switch(action.type) {
        case LOGOUT:
            return processosDefault;

        case GET_PROCESSOS: 
        return {
            ...state,
            processos: {
                ...state.processos,
                data: action.payload,
                dataFinal: action.payload,
                loading: false
            }
        }        
        case LOADING_PROCESSOS:         
        return {
            ...state,
            processos: {
                ...state.processos,
                data: null,
                loading: true,
                dataFinal: null
            }
        }
        case PROCESSOS_NOT_FOUND:         
        return {
            ...state,
            processos: {
                data: null,
                loading: false,
                dataFinal: null
            }
        }

        case FILTRO_PROCESSOS: 
        return {
            ...state,
            processos: {
                ...state.processos,
                dataFinal: action.payload,
                loading: false
            }
        }
        case LOADING_FILTROS: 
        return {
            ...state,
            processos: {
                ...state.processos,
                loading: true,
                dataFinal: null
            }
        }        
        case FILTRO_NOT_FOUND: 
        return {
            ...state,
            processos: {
                ...state.processos,                
                loading: false,
                dataFinal: null
            }
        }

        case GET_PROCESSOS_RASTREIO:
            return {
                ...state,
                processoRastreio: {
                    data: action.payload.data,
                    arquivos: action.payload.arquivos,
                    codigoProcesso: action.payload.codigoProcesso,
                    loading: false
                }
            }
        case LOADING_PROCESSOS_RASTREIO:
            return {
                ...state,
                processoRastreio: {
                    ...state.processoRastreio,
                    data: null,
                    arquivos: null,
                    codigoProcesso: null,
                    loading: true
                }
            }
        case PROCESSOS_RASTREIO_NOT_FOUND:
            return {
                ...state,
                processoRastreio: {
                    ...state.processoRastreio,
                    data: null,
                    arquivos: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default processosReducer;