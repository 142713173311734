import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import ConnectTo from '../store/connect';
import { guard, loading } from '../store/reducers/auth/actions';

import TrocaSenha from '../views/auth/troca';
import Loading from './Loading';

const AuthGuard = props => {

  const [isMounted, setIsMounted] = useState(false);
  const { auth, isAuthenticated, dispatch, isLoading } = props;

  const load = useCallback(async () => {
    dispatch(loading());
    dispatch(await guard());
  }, [dispatch]);

  useEffect(() => {
    if (!isMounted) {
      load();
      setIsMounted(true);
    }
  }, [load, setIsMounted, isMounted]);

  const hasRef = !!localStorage.getItem('ref');

  if (!hasRef && !isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (isLoading) {
    return (
      <Loading />
    );
  } else if (auth && auth.troca === 1) {
    return (
      <TrocaSenha />
    )
  } else {
    return (
      <>
        {props.children}
      </>
    );
  }
};

const mapStateToProps = ({ auth, isAuthenticated, isLoading }, props) => {
  return {
    auth,
    isLoading,
    isAuthenticated,
    ...props
  }
}

export default ConnectTo(mapStateToProps)(AuthGuard);
