const GET_PROCESSOS = 'GET_PROCESSOS';
const LOADING_PROCESSOS = 'LOADING_PROCESSOS';
const PROCESSOS_NOT_FOUND = 'PROCESSOS_NOT_FOUND';

const FILTRO_PROCESSOS = 'FILTRO_PROCESSOS';
const LOADING_FILTROS = 'LOADING_FILTROS';
const FILTRO_NOT_FOUND = 'FILTRO_NOT_FOUND';

const GET_PROCESSOS_RASTREIO = 'GET_PROCESSOS_RASTREIO';
const LOADING_PROCESSOS_RASTREIO = 'LOADING_PROCESSOS_RASTREIO';
const PROCESSOS_RASTREIO_NOT_FOUND = 'PROCESSOS_RASTREIO_NOT_FOUND';

export {
    
    GET_PROCESSOS,
    LOADING_PROCESSOS,
    PROCESSOS_NOT_FOUND,

    FILTRO_PROCESSOS,
    LOADING_FILTROS,
    FILTRO_NOT_FOUND,

    GET_PROCESSOS_RASTREIO,
    LOADING_PROCESSOS_RASTREIO,
    PROCESSOS_RASTREIO_NOT_FOUND

}