import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import ConnectTo from '../../../store/connect';
import { trocarSenha, loading, logout } from '../../../store/reducers/auth/actions';
import FormTroca from "./FormTroca";

const Troca = props => {

    const { auth, isLoading, dispatch } = props;

    const call = async form => {
        dispatch(loading());
        dispatch(await trocarSenha({ ...form, auth }));
    }

    const handleSair = () => {
        dispatch(logout());
    }

    useEffect(() => {        
        if (auth.troca === 0) {
            return <Redirect to="/home" />;
        }
    }, [auth.troca]);
    
    return (
        <main className="main" id="top">
            <div className="container-fluid">
                <div className="row min-vh-100 flex-center g-0">
                    <div className="col-lg-8 col-xxl-5 py-3 position-relative">
                        <img className="bg-auth-circle-shape" src="assets/img/illustrations/bg-shape.png" alt="" width="250" />
                        <img className="bg-auth-circle-shape-2" src="assets/img/illustrations/shape-1.png" alt="" width="150" />
                        <div className="card overflow-hidden z-index-1">
                            <div className="card-body p-0">
                                <div className="row g-0 h-100">
                                    <div className="col-md-5 text-center bg-card-gradient">
                                        <div className="position-relative p-4 pt-md-5 pb-md-7 light">
                                            <div className="bg-holder bg-auth-card-shape" style={{ backgroundImage: `url(assets/img/illustrations/half-circle.png)` }}></div>
                                            <div className="z-index-1 position-relative"><a className="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder" href="index.html">Portal do RH</a>
                                                <p className="opacity-75 text-white">O Portal do RH é mais um canal de comunição e acompanhamento dos seus contratos de seguro saúde, odontológico e vida junto a sua corretora.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 d-flex flex-center">
                                        <div className="p-4 p-md-5 flex-grow-1">
                                            <div className="row flex-between-center">
                                                <div className="col-auto">
                                                    <h3>Troca de Senha</h3>
                                                </div>
                                            </div>
                                            <FormTroca
                                                onCall={call}
                                                onLogout={handleSair}
                                                loading={isLoading}
                                                statusSenha={auth.senhaInvalida}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = (
    {
        auth,
        isLoading
    }, props) => {
    return {
        auth,
        isLoading,
        ...props
    }
}

export default ConnectTo(mapStateToProps)(Troca);