const GET_SEGURADOS = 'GET_SEGURADOS';
const LOADING_SEGURADOS = 'LOADING_SEGURADOS';
const SELECIONAR_SEGURADO = 'SELECIONAR_SEGURADO';
const SEGURADOS_NOT_FOUND = 'SEGURADOS_NOT_FOUND';

const GET_VERBAS_ADICIONAIS = 'GET_VERBAS_ADICIONAIS';
const LOADING_VERBAS_ADICIONAIS = 'LOADING_VERBAS_ADICIONAIS';
const VERBAS_ADICIONAIS_NOT_FOUND = 'VERBAS_ADICIONAIS_NOT_FOUND';

const GET_CAMPOS_ADICIONAIS = 'GET_CAMPOS_ADICIONAIS';
const LOADING_CAMPOS_ADICIONAIS = 'LOADING_CAMPOS_ADICIONAIS';
const CAMPOS_ADICIONAIS_NOT_FOUND = 'CAMPOS_ADICIONAIS_NOT_FOUND';

const GET_PLANOS_VALORES = 'GET_PLANOS_VALORES';
const LOADING_PLANOS_VALORES = 'LOADING_PLANOS_VALORES';
const PLANOS_VALORES_NOT_FOUND = 'PLANOS_VALORES_NOT_FOUND';

export { 
    GET_SEGURADOS, 
    LOADING_SEGURADOS, 
    SEGURADOS_NOT_FOUND, 
    SELECIONAR_SEGURADO,

    GET_VERBAS_ADICIONAIS,
    LOADING_VERBAS_ADICIONAIS,
    VERBAS_ADICIONAIS_NOT_FOUND,

    GET_CAMPOS_ADICIONAIS,
    LOADING_CAMPOS_ADICIONAIS,
    CAMPOS_ADICIONAIS_NOT_FOUND,

    GET_PLANOS_VALORES,
    LOADING_PLANOS_VALORES,
    PLANOS_VALORES_NOT_FOUND
};