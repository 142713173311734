import {
    GET_MESES_FATURAS,
    LOADING_MESES_FATURAS,
    MESES_FATURAS_NOT_FOUND,
    SELECIONAR_MES_FATURA,

    GET_LISTA_FATURAS,
    LOADING_LISTA_FATURAS,
    LISTA_FATURAS_NOT_FOUND,

    GET_RESUMO_FATURA,
    LOADING_RESUMO_FATURA,
    RESUMO_FATURA_NOT_FOUND,

    GET_MOVIMENTOS,
    LOADING_MOVIMENTOS,
    MOVIMENTOS_NOT_FOUND,

    GET_SEGUNDA_VIA,
    LOADING_SEGUNDA_VIA,
    SEGUNDA_VIA_NOT_FOUND,

    GET_TAXA_IMPLANTACAO,
    LOADING_TAXA_IMPLANTACAO,
    TAXA_IMPLANTACAO_NOT_FOUND,

    GET_FATURA_VERBAS,
    LOADING_FATURA_VERBAS,
    FATURA_VERBAS_NOT_FOUND,

    GET_FATURA_ARQUIVOS,
    LOADING_FATURA_ARQUIVOS,
    FATURA_ARQUIVOS_NOT_FOUND,

    GET_REAJUSTES,
    LOADING_REAJUSTES,
    REAJUSTES_NOT_FOUND,

    GET_AJUSTES,
    LOADING_AJUSTES,
    AJUSTES_NOT_FOUND,

    GET_FATURA_BOLETOS,
    LOADING_FATURA_BOLETOS,
    FATURA_BOLETOS_NOT_FOUND
} from './types';
import { LOADING_CONTRATOS } from '../contratos/types';
import { LOGOUT } from '../auth/types';

export const faturasDefault = {
    mesesFaturas: {
        data: null,
        codigo_contrato: null,
        mes_ano_referencia: null,
        loading: false
    },
    listaFaturas: {
        data: null,
        codigo_contrato: null,
        loading: false
    },
    resumoFatura: {
        data: null,
        codigo_contrato: null,
        mes_ano_referencia: null,
        loading: false
    },
    movimentos: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    segundaVia: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    taxaImplantacao: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    faturaVerbas: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    faturaArquivos: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    faturaReajustes: {
        resumoData: null,
        tabelaData: null,
        mes_ano_referencia: null,
        loading: false
    },
    ajustesFatura: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
    faturaBoletos: {
        data: null,
        mes_ano_referencia: null,
        loading: false
    },
}

const faturasReducer = (state = faturasDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return faturasDefault;
        case LOADING_CONTRATOS:
            return faturasDefault;

        case GET_MESES_FATURAS:
            return {
                ...state,
                mesesFaturas: {
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    mes_ano_referencia: action.payload.data && action.payload.data.length > 0 ? action.payload.data[0].mes_ano_referencia : null,
                    loading: false
                }
            }
        case LOADING_MESES_FATURAS:
            return {
                ...state,
                mesesFaturas: {
                    ...state.mesesFaturas,
                    data: null,
                    mes_ano_referencia: null,
                    loading: true
                },
                resumoFatura: {
                    ...faturasDefault.resumoFatura
                },
                movimentos: {
                    ...faturasDefault.movimentos
                },
                segundaVia: {
                    ...faturasDefault.segundaVia
                },
                taxaImplantacao: {
                    ...faturasDefault.taxaImplantacao
                },
                faturaVerbas: {
                    ...faturasDefault.faturaVerbas
                },
                faturaArquivos: {
                    ...faturasDefault.faturaArquivos
                },
                faturaReajustes: {
                    ...faturasDefault.faturaReajustes
                },
                faturaBoletos: {
                    ...faturasDefault.faturaBoletos
                },
            }
        case MESES_FATURAS_NOT_FOUND:
            return {
                ...state,
                mesesFaturas: {
                    ...state.mesesFaturas,
                    data: null,
                    mes_ano_referencia: null,
                    loading: false
                }
            }
        case SELECIONAR_MES_FATURA:
            return {
                ...state,
                mesesFaturas: {
                    ...state.mesesFaturas,
                    mes_ano_referencia: action.payload
                }
            }

        case GET_LISTA_FATURAS:
            return {
                ...state,
                listaFaturas: {
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    loading: false
                }
            }
        case LOADING_LISTA_FATURAS:
            return {
                ...state,
                listaFaturas: {
                    ...state.listaFaturas,
                    data: null,
                    loading: true
                },
            }
        case LISTA_FATURAS_NOT_FOUND:
            return {
                ...state,
                listaFaturas: {
                    ...state.listaFaturas,
                    data: null,
                    loading: false
                }
            }

        case GET_RESUMO_FATURA:
            return {
                ...state,
                resumoFatura: {
                    data: action.payload.data,
                    codigo_contrato: action.payload.codigo_contrato,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_RESUMO_FATURA:
            return {
                ...state,
                resumoFatura: {
                    ...state.resumoFatura,
                    loading: true
                }
            }
        case RESUMO_FATURA_NOT_FOUND:
            return {
                ...state,
                resumoFatura: {
                    ...state.resumoFatura,
                    data: null,
                    loading: false
                }
            }

        case GET_MOVIMENTOS:
            return {
                ...state,
                movimentos: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_MOVIMENTOS:
            return {
                ...state,
                movimentos: {
                    ...state.movimentos,
                    loading: true
                }
            }
        case MOVIMENTOS_NOT_FOUND:
            return {
                ...state,
                movimentos: {
                    ...state.movimentos,
                    data: null,
                    loading: false
                }
            }

        case GET_SEGUNDA_VIA:
            return {
                ...state,
                segundaVia: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_SEGUNDA_VIA:
            return {
                ...state,
                segundaVia: {
                    ...state.segundaVia,
                    loading: true
                }
            }
        case SEGUNDA_VIA_NOT_FOUND:
            return {
                ...state,
                segundaVia: {
                    ...state.segundaVia,
                    data: null,
                    loading: false
                }
            }

        case GET_TAXA_IMPLANTACAO:
            return {
                ...state,
                taxaImplantacao: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_TAXA_IMPLANTACAO:
            return {
                ...state,
                taxaImplantacao: {
                    ...state.taxaImplantacao,
                    loading: true
                }
            }
        case TAXA_IMPLANTACAO_NOT_FOUND:
            return {
                ...state,
                taxaImplantacao: {
                    ...state.taxaImplantacao,
                    data: null,
                    loading: false
                }
            }

        case GET_FATURA_VERBAS:
            return {
                ...state,
                faturaVerbas: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_FATURA_VERBAS:
            return {
                ...state,
                faturaVerbas: {
                    ...state.faturaVerbas,
                    loading: true
                }
            }
        case FATURA_VERBAS_NOT_FOUND:
            return {
                ...state,
                faturaVerbas: {
                    ...state.faturaVerbas,
                    data: null,
                    loading: false
                }
            }

        case GET_FATURA_ARQUIVOS:
            return {
                ...state,
                faturaArquivos: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_FATURA_ARQUIVOS:
            return {
                ...state,
                faturaArquivos: {
                    ...state.faturaArquivos,
                    loading: true
                }
            }
        case FATURA_ARQUIVOS_NOT_FOUND:
            return {
                ...state,
                faturaArquivos: {
                    ...state.faturaArquivos,
                    data: null,
                    loading: false
                }
            }

        case GET_REAJUSTES:
            return {
                ...state,
                faturaReajustes: {
                    resumoData: action.payload.resumoData,
                    tabelaData: action.payload.tabelaData,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_REAJUSTES:
            return {
                ...state,
                faturaReajustes: {
                    ...state.faturaReajustes,
                    loading: true
                }
            }
        case REAJUSTES_NOT_FOUND:
            return {
                ...state,
                faturaReajustes: {
                    ...state.faturaReajustes,
                    resumoData: null,
                    tabelaData: null,
                    loading: false
                }
            }

        case GET_AJUSTES:
            return {
                ...state,
                ajustesFatura: {
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_AJUSTES:
            return {
                ...state,
                ajustesFatura: {
                    ...state.ajustesFatura,
                    loading: true
                }
            }
        case AJUSTES_NOT_FOUND:
            return {
                ...state,
                ajustesFatura: {
                    ...state.ajustesFatura,
                    data: null,
                    loading: false
                }
            }

        case GET_FATURA_BOLETOS:
            return {
                ...state,
                faturaBoletos: {
                    ...state.faturaBoletos,
                    data: action.payload.data,
                    mes_ano_referencia: action.payload.mes_ano_referencia,
                    loading: false
                }
            }
        case LOADING_FATURA_BOLETOS:
            return {
                ...state,
                faturaBoletos: {
                    ...state.faturaBoletos,
                    loading: true
                }
            }
        case FATURA_BOLETOS_NOT_FOUND:
            return {
                ...state,
                faturaBoletos: {
                    ...state.faturaBoletos,
                    data: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default faturasReducer;