const GET_FILTRO_TIPO_MOVIMENTO = 'GET_FILTRO_TIPO_MOVIMENTO';
const LOADING_FILTRO_TIPO_MOVIMENTO = 'LOADING_FILTRO_TIPO_MOVIMENTO';
const FILTRO_TIPO_MOVIMENTO_NOT_FOUND = 'FILTRO_TIPO_MOVIMENTO_NOT_FOUND';

const GET_FILTRO_SEGURADOS = 'GET_FILTRO_SEGURADOS';
const LOADING_FILTRO_SEGURADOS = 'LOADING_FILTRO_SEGURADOS';
const FILTRO_SEGURADOS_NOT_FOUND = 'FILTRO_SEGURADOS_NOT_FOUND';

export {
    GET_FILTRO_TIPO_MOVIMENTO,
    LOADING_FILTRO_TIPO_MOVIMENTO,
    FILTRO_TIPO_MOVIMENTO_NOT_FOUND,

    GET_FILTRO_SEGURADOS,
    LOADING_FILTRO_SEGURADOS,
    FILTRO_SEGURADOS_NOT_FOUND
}