const GET_ARQUIVOS_FORMULARIOS = 'GET_ARQUIVOS_FORMULARIOS';
const LOADING_ARQUIVOS_FORMULARIOS = 'LOADING_ARQUIVOS_FORMULARIOS';
const ARQUIVOS_FORMULARIOS_NOT_FOUND = 'ARQUIVOS_FORMULARIOS_NOT_FOUND';

const GET_ARQUIVOS_PUBLICACOES = 'GET_ARQUIVOS_PUBLICACOES';
const LOADING_ARQUIVOS_PUBLICACOES = 'LOADING_ARQUIVOS_PUBLICACOES';
const ARQUIVOS_PUBLICACOES_NOT_FOUND = 'ARQUIVOS_PUBLICACOES_NOT_FOUND';

export {
    GET_ARQUIVOS_FORMULARIOS,
    LOADING_ARQUIVOS_FORMULARIOS,
    ARQUIVOS_FORMULARIOS_NOT_FOUND,

    GET_ARQUIVOS_PUBLICACOES,
    LOADING_ARQUIVOS_PUBLICACOES,
    ARQUIVOS_PUBLICACOES_NOT_FOUND
};