import { GET_UNIDADES, UNIDADE_NOT_FOUND, SELECIONAR_UNIDADE, LOADING_UNIDADE } from './types';
import { LOGOUT } from '../auth/types';

export const unidadesDefault = {
    unidades: {
        data: null,
        codigo_unidade: null,
        loading: false,
        hasTodos: false,
        hasSelecione: false
    }
}

const unidadesReducer = (state = unidadesDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return unidadesDefault
        case GET_UNIDADES:
            return {
                ...state,
                unidades: {
                    data: action.payload.data,
                    codigo_unidade: action.payload.data[0].codigo,
                    hasTodos: action.payload.hasTodos,
                    hasSelecione: action.payload.hasSelecione,
                    loading: false
                }
            }
        case SELECIONAR_UNIDADE:
            return {
                ...state,
                unidades: {
                    ...state.unidades,
                    codigo_unidade: action.payload
                }
            }
        case UNIDADE_NOT_FOUND:
            return {
                ...state,
                unidades: {
                    ...state.unidades,
                    data: null,
                    loading: false
                }
            }
        case LOADING_UNIDADE:
            return {
                ...state,
                unidades: {
                    ...state.unidades,
                    data: null,
                    codigo_unidade: null,
                    loading: true
                }
            }
        default: return state
    }
}

export default unidadesReducer;