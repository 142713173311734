import {
    GET_ATENDIMENTOS,
    LOADING_ATENDIMENTOS,
    ATENDIMENTOS_NOT_FOUND,

    FILTRO_ATENDIMENTOS,
    LOADING_FILTROS_ATENDIMENTOS,
    FILTRO_ATENDIMENTOS_NOT_FOUND,

    GET_ATENDIMENTO_RASTREIO,
    LOADING_ATENDIMENTO_RASTREIO,
    ATENDIMENTO_RASTREIO_NOT_FOUND,

    GET_ATENDIMENTO_ASSUNTOS,
    LOADING_ATENDIMENTO_ASSUNTOS,
    ATENDIMENTO_ASSUNTOS_NOT_FOUND,

    GET_LISTA_DOCUMENTOS,
    LOADING_LISTA_DOCUMENTOS,
    LISTA_DOCUMENTOS_NOT_FOUND
} from './types';
import { LOGOUT } from '../auth/types';

export const atendimentosDefault = {
    atendimentos: {
        data: null,
        loading: false,
        dataFinal: null
    },
    atendimentoRastreio: {
        data: null,
        movimentos: null,
        arquivos: null,
        loading: false,
        codigoAtendimento: null
    },
    atendimentoAssuntos: {
        data: null,
        loading: false
    },
    listaDocumentos: {
        data: null,
        loading: false
    }
}

const atendimentosReducer = (state = atendimentosDefault, action) => {
    switch (action.type) {
        case LOGOUT:
            return atendimentosDefault;

        case GET_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: {
                    data: action.payload,
                    dataFinal: action.payload,
                    loading: false
                },
                atendimentoRastreio: {
                    ...atendimentosDefault.atendimentoRastreio
                }
            }
        case LOADING_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: {
                    ...state.atendimentos,
                    data: null,
                    dataFinal: null,
                    loading: true
                }
            }
        case ATENDIMENTOS_NOT_FOUND:
            return {
                ...state,
                atendimentos: {
                    ...state.atendimentos,
                    data: null,
                    dataFinal: null,
                    loading: false
                }
            }

        case FILTRO_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: {
                    ...state.atendimentos,
                    dataFinal: action.payload,
                    loading: false
                }
            }
        case LOADING_FILTROS_ATENDIMENTOS:
            return {
                ...state,
                atendimentos: {
                    ...state.atendimentos,
                    dataFinal: null,
                    loading: true
                }
            }
        case FILTRO_ATENDIMENTOS_NOT_FOUND:
            return {
                ...state,
                atendimentos: {
                    ...state.atendimentos,
                    dataFinal: null,
                    loading: false
                }
            }

        case GET_ATENDIMENTO_RASTREIO:
            return {
                ...state,
                atendimentoRastreio: {
                    data: action.payload.data,
                    arquivos: action.payload.arquivos,
                    movimentos: action.payload.movimentos,
                    codigoAtendimento: action.payload.codigoAtendimento,
                    loading: false
                }
            }
        case LOADING_ATENDIMENTO_RASTREIO:
            return {
                ...state,
                atendimentoRastreio: {
                    ...state.atendimentoRastreio,
                    data: null,
                    arquivos: null,
                    movimentos: null,
                    codigoAtendimento: null,
                    loading: true
                }
            }
        case ATENDIMENTO_RASTREIO_NOT_FOUND:
            return {
                ...state,
                atendimentoRastreio: {
                    ...state.atendimentoRastreio,
                    data: null,
                    arquivos: null,
                    movimentos: null,
                    loading: false
                }
            }

        case GET_ATENDIMENTO_ASSUNTOS:
            return {
                ...state,
                atendimentoAssuntos: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_ATENDIMENTO_ASSUNTOS:
            return {
                ...state,
                atendimentoAssuntos: {
                    ...state.atendimentoAssuntos,
                    data: null,
                    loading: true
                }
            }
        case ATENDIMENTO_ASSUNTOS_NOT_FOUND:
            return {
                ...state,
                atendimentoAssuntos: {
                    ...state.atendimentoAssuntos,
                    data: null,
                    loading: false
                }
            }

        case GET_LISTA_DOCUMENTOS:
            return {
                ...state,
                listaDocumentos: {
                    data: action.payload,
                    loading: false
                }
            }
        case LOADING_LISTA_DOCUMENTOS:
            return {
                ...state,
                listaDocumentos: {
                    ...state.listaDocumentos,
                    data: null,
                    loading: true
                }
            }
        case LISTA_DOCUMENTOS_NOT_FOUND:
            return {
                ...state,
                listaDocumentos: {
                    ...state.listaDocumentos,
                    data: null,
                    loading: false
                }
            }

        default: return state;
    }
}

export default atendimentosReducer;