import ConnecTo from '../store/connect';

const LogoCorretora = props => {

    const { corretora, size, style } = props;
    const hasLogotipo = corretora?.logo_cliente || corretora?.logotipo;
    
    if (hasLogotipo) {
        return (
            <img src={corretora?.logotipo || corretora?.logo_cliente} alt="logotipo" height={size || 60} style={{ marginRight: 10, ...style }} />
        )
    } else {
        return false;
    }
}

const mapStateToProps = ({ corretora }, props) => {
    return {
        corretora,
        ...props
    }
}

export default ConnecTo(mapStateToProps)(LogoCorretora);